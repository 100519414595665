import { createAction } from '@ngrx/store';
import { CategoryBranch, Product, NamedSchemaObj, NamedSchemaCreateObj, NamedSchemaIdObj, CategoryUpsert, ProductCreateObj, CategoryInfo, PropertyObj, PropertyCreateObj } from 'src/app/models';

export const resetProductState = createAction('[Product] RESETPRODUCTSTATE');

export const getCategories = createAction('[Product] GETCATEGORIES');
export const getCategoriesSuccess = createAction('[Product] GETCATEGORIES_SUCCESS', (categories: { value: CategoryBranch[] }) => categories);
export const getCategoriesError = createAction('[Product] GETCATEGORIES_ERROR', (error: any) => error);

export const addCategory = createAction('[Product] ADDCATEGORY', (categoryUpsert: { value: CategoryUpsert }) => categoryUpsert);
export const addCategorySuccess = createAction('[Product] ADDCATEGORY_SUCCESS', (categories: { value: CategoryBranch[] }) => categories);
export const addCategoryError = createAction('[Product] ADDCATEGORY_ERROR', (error: any) => error);

export const editCategory = createAction('[Product] EDITCATEGORY', (categoryUpsert: { id: string,  value: CategoryUpsert }) => categoryUpsert);
export const editCategorySuccess = createAction('[Product] EDITCATEGORY_SUCCESS', (categories: { value: CategoryBranch[] }) => categories);
export const editCategoryError = createAction('[Product] EDITCATEGORY_ERROR', (error: any) => error);

export const deleteCategory = createAction('[Product] DELETECATEGORY', (category: { id: string }) => category);
export const deleteCategorySuccess = createAction('[Product] DELETECATEGORY_SUCCESS', (categories: { value: CategoryBranch[] }) => categories);
export const deleteCategoryError = createAction('[Product] DELETECATEGORY_ERROR', (error: any) => error);

export const getProductsInCategories = createAction('[Product] GETPRODUCTSINCATEGORIES', (categoryId: { value: string }) => categoryId);
export const getProductsInCategoriesSuccess = createAction('[Product] GETPRODUCTSINCATEGORIES_SUCCESS', (products: { value: Product[] }) => products);
export const getProductsInCategoriesError = createAction('[Product] GETPRODUCTSINCATEGORIES_ERROR', (error: any) => error);

export const selectCategory = createAction('[Product] SELECTCATEGORY', (category: { value: CategoryInfo }) => category);
export const unselectCategory = createAction('[Product] UNSELECTCATEGORY');
export const clearCategorySchema = createAction('[Product] CLEARCATEGORYSCHEMA');
export const clearProducts = createAction('[Product] CLEARPRODUCTS');

export const getCategorySchema = createAction('[Product] GETCATEGORYSCHEMA', (categoryId: { value: string }) => categoryId);
export const getCategorySchemaSuccess = createAction('[Product] GETCATEGORYSCHEMA_SUCCESS', (schema: { value: NamedSchemaObj }) => schema);
export const getCategorySchemaError = createAction('[Product] GETCATEGORYSCHEMA_ERROR', (error: any) => error);

export const assignCategoryNamedSchema = createAction('[Product] ASSIGNCATEGORYNAMEDSCHEMA', (schemaAssign: { categoryId: string, value: NamedSchemaIdObj }) => schemaAssign);
export const assignCategoryNamedSchemaSuccess = createAction('[Product] ASSIGNCATEGORYNAMEDSCHEMA_SUCCESS', (schema: { value: NamedSchemaObj }) => schema);
export const assignCategoryNamedSchemaError = createAction('[Product] ASSIGNCATEGORYNAMEDSCHEMA_ERROR', (error: any) => error);

export const createNamedSchema = createAction('[Product] CREATENAMEDSCHEMA', (schema: { value: NamedSchemaCreateObj }) => schema);
export const createSchemaSuccess = createAction('[Product] CREATENAMEDSCHEMA_SUCCESS', (schema: { value: NamedSchemaObj }) => schema);
export const createSchemaError = createAction('[Product] CREATENAMEDSCHEMA_ERROR', (error: any) => error);

export const getNamedSchema = createAction('[Product] GETNAMEDSCHEMA', (schemaId: { value: string }) => schemaId);
export const getNamedSchemaSuccess = createAction('[Product] GETNAMEDSCHEMA_SUCCESS', (schema: { value: NamedSchemaObj }) => schema);
export const getNamedSchemaError = createAction('[Product] GETNAMEDSCHEMA_ERROR', (error: any) => error);

export const getDefaultNamedSchema = createAction('[Product] GETDEFAULTNAMEDSCHEMA');
export const getDefaultNamedSchemaSuccess = createAction('[Product] GETDEFAULTNAMEDSCHEMA_SUCCESS', (schema: { value: NamedSchemaObj }) => schema);
export const getDefaultNamedSchemaError = createAction('[Product] GETDEFAULTNAMEDSCHEMA_ERROR', (error: any) => error);

export const assignDefaultNamedSchema = createAction('[Product] ASSIGNDEFAULTNAMEDSCHEMA', (schemaId: { value: NamedSchemaIdObj }) => schemaId);
export const assignDefaultNamedSchemaSuccess = createAction('[Product] ASSIGNDEFAULTNAMEDSCHEMA_SUCCESS', (schema: { value: NamedSchemaObj }) => schema);
export const assignDefaultNamedSchemaError = createAction('[Product] ASSIGNDEFAULTNAMEDSCHEMA_ERROR', (error: any) => error);

export const getSampleNamedSchemas = createAction('[Product] GETSAMPLENAMEDSCHEMAS');
export const getSampleNamedSchemasSuccess = createAction('[Product] GETSAMPLENAMEDSCHEMAS_SUCCESS', (schema: { value: NamedSchemaObj[] }) => schema);
export const getSampleNamedSchemasError = createAction('[Product] GETSAMPLENAMEDSCHEMAS_ERROR', (error: any) => error);

export const getNamedSchemas = createAction('[Product] GETNAMEDSCHEMAS', (schemaFetch: { pageSize: number | null, pageOffset: number | null, includeTotal: boolean | null }) => schemaFetch);
export const getNamedSchemasSuccess = createAction('[Product] GETNAMEDSCHEMAS_SUCCESS', (schemas: { value: NamedSchemaObj[] }) => schemas);
export const getNamedSchemasError = createAction('[Product] GETNAMEDSCHEMAS_ERROR', (error: any) => error);

export const updateNamedSchema = createAction('[Product] UPDATENAMEDSCHEMA', (schema: { id: string, value: NamedSchemaCreateObj }) => schema);
export const updateNamedSchemaSuccess = createAction('[Product] UPDATENAMEDSCHEMA_SUCCESS', (schema: { value: NamedSchemaObj }) => schema);
export const updateNamedSchemaError = createAction('[Product] UPDATENAMEDSCHEMA_ERROR', (error: any) => error);

export const createProduct = createAction('[Product] CREATEPRODUCT', (product: { value: ProductCreateObj, categoryId: string }) => product);
export const createProductSuccess = createAction('[Product] CREATEPRODUCT_SUCCESS', (product: { value: Product }) => product);
export const createProductError = createAction('[Product] CREATEPRODUCT_ERROR', (error: any) => error);

export const updateProduct = createAction('[Product] UPDATEPRODUCT', (product: { value: ProductCreateObj, productId: string }) => product);
export const updateProductSuccess = createAction('[Product] UPDATEPRODUCT_SUCCESS', (product: { value: Product }) => product);
export const updateProductError = createAction('[Product] UPDATEPRODUCT_ERROR', (error: any) => error);

export const deleteProduct = createAction('[Product] DELETEPRODUCT', (product: { id: string }) => product);
export const deleteProductSuccess = createAction('[Product] DELETEPRODUCT_SUCCESS', (product: { id: string }) => product);
export const deleteProductError = createAction('[Product] DELETEPRODUCT_ERROR', (error: any) => error);

export const getCategoryProperties = createAction('[Product] GETCATEGORYPROPERTIES', (categoryId: { value: string }) => categoryId);
export const getCategoryPropertiesSuccess = createAction('[Product] GETCATEGORYPROPERTIES_SUCCESS', (categoryProperties: { value: PropertyObj[] }) => categoryProperties);
export const getCategoryPropertiesError = createAction('[Product] GETCATEGORYPROPERTIES_ERROR', (error: any) => error);

export const createCategoryProperties = createAction('[Product] CREATECATEGORYPROPERTIES', (property: { value: PropertyCreateObj, categoryId: string }) => property);
export const createCategoryPropertiesSuccess = createAction('[Product] CREATECATEGORYPROPERTIES_SUCCESS', (categoryProperty: { value: PropertyObj }) => categoryProperty);
export const createCategoryPropertiesError = createAction('[Product] CREATECATEGORYPROPERTIES_ERROR', (error: any) => error);

export const updateCategoryProperty = createAction('[Product] UPDATECATEGORYPROPERTY', (property: { value: PropertyCreateObj, propertyId: string }) => property);
export const updateCategoryPropertySuccess = createAction('[Product] UPDATECATEGORYPROPERTY_SUCCESS', (property: { value: PropertyObj }) => property);
export const updateCategoryPropertyError = createAction('[Product] UPDATECATEGORYPROPERTY_ERROR', (error: any) => error);

export const deleteCategoryProperty = createAction('[Product] DELETECATEGORYPROPERTY', (property: { id: string }) => property);
export const deleteCategoryPropertySuccess = createAction('[Product] DELETECATEGORYPROPERTY_SUCCESS', (property: { id: string }) => property);
export const deleteCategoryPropertyError = createAction('[Product] DELETECATEGORYPROPERTY_ERROR', (error: any) => error);
