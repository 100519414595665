import { AddressCreate } from './address.model';

export interface CompanyDelete {
  id: string;
};

export interface CompanyCreate {
  nameId: string;
  companyName: string;
  description: string;
};

export interface Company extends CompanyCreate {
  companyId: string;
  dateCreated: Date;
  dateUpdated: Date;
  createdBy: string;
  updatedBy: string;
  active: boolean;
}
