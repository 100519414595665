import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Preferences } from '@capacitor/preferences';
import { Router } from '@angular/router';
import { Observable, takeWhile } from 'rxjs';

import * as MyStore from './store';
import { Company } from './models';
import { AuthService } from './services';
import { SchemaBuilderComponent } from './widgets/schema-builder/schema-builder.component';
import { Schema } from '@dashjoin/json-schema-form';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'stall-owner';
  alive = true;
  companiesState$: Observable<Company[]>;
  selectedCompany$: Observable<string | null>;
  accessToken$: Observable<string | null>;

  @ViewChild(SchemaBuilderComponent) child!: SchemaBuilderComponent;

  constructor(
    private store$: Store<MyStore.AppState>,
    private logger: NGXLogger,
    private router: Router,
    private auth: AuthService,
  ) {
    // company list
    this.companiesState$ = this.store$
      .pipe(select(MyStore.selectCompanies))
      .pipe(takeWhile(() => this.alive));

    this.selectedCompany$ = this.store$
      .pipe(select(MyStore.selectSelectedCompany))
      .pipe(takeWhile(() => this.alive));

    this.accessToken$ = this.store$
      .pipe(select(MyStore.selectAccessToken))
      .pipe(takeWhile(() => this.alive));
  }

  async ngOnInit() {
    this.logger.log('AppComponent ngOnInit');

    // Check refresh-token from preferences
    const { value } = await Preferences.get({ key: 'refresh-token' });
    if (value) this.store$.dispatch(MyStore.reloadRefreshToken({ value }));

    this.logger.log('route',this.router.url);

    this.accessToken$.subscribe((accessToken) => {
        if(accessToken==null) return;

        if(this.auth.isValidAccessToken() && this.router.url == '/') {
          this.router.navigateByUrl('/dashboard');
        }
      });
  }

  changeSelectedCompany = (companyId: string, currentCompanyId: string | null) => {
    this.logger.log('AppComponent.changeSelectedCompany entry', companyId);
    if (currentCompanyId != companyId) {
      this.logger.log('AppComponent.changeSelectedCompany', 'changing company');
      this.store$.dispatch(MyStore.resetProductState());
      this.store$.dispatch(MyStore.selectCompany({ 'value': companyId }));

      // TODO navigate to base link instead of dashboard
      this.router.navigateByUrl('/dashboard');
    }
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
}
