import { HttpRequest, HttpHandler, HttpInterceptor } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, switchMap, take, takeWhile } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { Device } from '@capacitor/device';

import { DeviceInfo, Tokens, TokensForAccess } from '../models';
import { AuthService } from '../services';
import * as MyStore from '../store';
import { AppSettings } from '../app.settings';
import { environment } from '../../environments/environment';

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
  alive = true;
  private tokens: Tokens = MyStore.initialAuthState.tokens;
  deviceInfo: DeviceInfo | null = null;

  // // authService;
  // refreshTokenInProgress = false;

  // tokenRefreshedSource = new Subject();
  // tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  // no headers
  // idTokenList = [
  //   '/tokens',
  // ];

  // whitelistRefreshToken = [
  //   '/auth/logout',
  //   '/auth/token',
  // ];

  codeToken = [
    AppSettings.pathTokensCode,
  ];

  refreshTokenList = [
    AppSettings.pathTokensRevoke,
    AppSettings.pathTokensRefresh,
  ];

  blacklistAccessToken = [
    '/auth/login',
    '/auth/logout',
    '/auth/token',
  ];

  constructor(
    private store$: Store<MyStore.AppState>,
    private injector: Injector,
    private authService: AuthService,
    private logger: NGXLogger,
  ) {
    this.logger.log('HttpAuthInterceptor constructor');
    this.store$
      .pipe(select(MyStore.selectToken))
      .pipe(takeWhile(() => this.alive))
      .subscribe((tokens) => {
        this.tokens = tokens;
      });

    Device.getInfo().then((deviceInfo) => {
      if (deviceInfo.memUsed) delete deviceInfo.memUsed;
      if (deviceInfo.diskFree) delete deviceInfo.diskFree;
      if (deviceInfo.diskTotal) delete deviceInfo.diskTotal;
      this.deviceInfo = deviceInfo;
    });
  }

  isBlackListed($url: string): boolean {
    for (const i of Object.keys(this.blacklistAccessToken)) {
      const temp: any = i;
      if ($url.search(this.blacklistAccessToken[temp]) !== -1) return true;
    }
    return false;
  }

  isRefreshListed($url: string): boolean {
    for (const i of Object.keys(this.refreshTokenList)) {
      const temp: any = i;
      if ($url.search(this.refreshTokenList[temp]) !== -1) return true;
    }
    return false;
  }

  // isWhiteListed($url: string): boolean {
  //   for (const i of Object.keys(this.whitelistRefreshToken)) {
  //     if ($url.search(this.whitelistRefreshToken[i]) !== -1) return true;
  //   }
  //   return false;
  // }

  // isIdTokenListed($url: string): boolean {
  //   for (const i of Object.keys(this.idTokenList)) {
  //     if ($url.search(this.idTokenList[i]) !== -1) return true;
  //   }
  //   return false;
  // }

  isCodeListed($url: string): boolean {
    for (const i of Object.keys(this.codeToken)) {
      const temp: any = i;
      if ($url.search(this.codeToken[temp]) !== -1) return true;
    }
    return false;
  }

  addAuthHeader(request: HttpRequest<any>) {
    this.logger.log('HttpAuthInterceptor addAuthHeader');

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams) {
      const code = urlParams.get('code');
      if (this.isCodeListed(request.url) && code != null) {
        this.logger.log('HttpAuthInterceptor use code');
        return request.clone({
          headers: request.headers.set('code', code).set('redirect_uri', encodeURIComponent(environment.cognito.redirectUri)),
        });
      }
    }

    if (this.isRefreshListed(request.url) && this.tokens['refresh-token'] != null) {
      this.logger.log('HttpAuthInterceptor use refresh-token');
      return request.clone({
        headers: request.headers.set('refresh_token', (this.tokens) ? this.tokens['refresh-token'] : '').set('redirect_uri', encodeURIComponent(environment.cognito.redirectUri)),
      });
    }

    if (!this.isBlackListed(request.url) && this.tokens['access-token'] != null) {
      this.logger.log('HttpAuthInterceptor use access-token');
      return request.clone({
        headers: request.headers.set('Authorization', (this.tokens) ? 'Bearer ' + this.tokens['access-token'] : ''),
      });
    }

    return request;
  }

  private isRefreshing = false;
  private refreshTokenSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  private addAccessToken(request: HttpRequest<any>, token: string | null) {
    this.logger.log('HttpAuthInterceptor addAccessToken');
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    this.logger.log('HttpAuthInterceptor handle401Error');
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject$.next(null);

      return this.authService.getTokensFromRefresh().pipe(
        switchMap((tokensForAccess: TokensForAccess) => {
          this.isRefreshing = false;
          this.store$.dispatch(MyStore.refreshTokensSuccess({ value: tokensForAccess, fetchData: false }));
          this.refreshTokenSubject$.next(tokensForAccess['access-token']);
          return next.handle(this.addAccessToken(request, tokensForAccess['access-token']));
        })
      );
    } else {
      return this.refreshTokenSubject$.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((jwt) => {
          return next.handle(this.addAccessToken(request, jwt));
        })
      );
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    this.logger.log('HttpAuthInterceptor intercept');
    this.authService = this.injector.get(AuthService);

    request = this.addAuthHeader(request);
    return next.handle(request).pipe(
      catchError((error) => {
        this.logger.log('HttpAuthInterceptor intercept', error);
        if (error.status === 401 && (!this.isBlackListed(request.url) && this.tokens['access-token'] != null)) {
          return this.handle401Error(request, next);
        }
        return throwError(() => new Error(error));
      }
    ));
  }
}
