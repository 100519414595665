import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import * as MyPages from './pages';
import { RouteGuardService as RouteGuard } from './services';

const routes: Routes = [
  {
    path: 'dashboard',
    component: MyPages.DashboardComponent,
    canActivate: [RouteGuard],
  },
  {
    path: 'login',
    component: MyPages.LoginComponent,
  },
  {
    path: 'loggedin',
    component: MyPages.LoggedinComponent,
  },
  {
    path: 'products',
    component: MyPages.ProductsComponent,
    canActivate: [RouteGuard],
  },
  {
    path: 'schemas',
    component: MyPages.SchemasComponent,
    canActivate: [RouteGuard],
  },
  {
    path: 'product-options',
    component: MyPages.OptionsComponent,
    canActivate: [RouteGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
