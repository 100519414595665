import { AuthState } from './auth.state';
import { CompanyState } from './company.state';
import { ProductState } from './product.state';
import { SelectOptionsState } from './select-options.state';
import { UserState } from './user.state';

export interface AppState {
  readonly auth: AuthState;
  readonly companies: CompanyState;
  readonly products: ProductState;
  readonly selectOptions: SelectOptionsState;
  readonly user: UserState;
}

export * from './auth.state';
export * from './company.state';
export * from './dashboard.state';
export * from './domain.state';
export * from './element.state';
export * from './inventory.state';
export * from './product.state';
export * from './ref.state';
export * from './select-options.state';
export * from './user.state';
