<mat-card class="general-card">
  <mat-card-header>
    <mat-card-title-group>
      <mat-card-title *ngIf="defaultSchema$ | async as defaultSchema">
        <span *ngIf="defaultSchema!=null">{{defaultSchema.name}}</span>
      </mat-card-title>
      <mat-card-subtitle>Default schema</mat-card-subtitle>
      <button mat-icon-button aria-label="options" [matMenuTriggerFor]="defaultSchemaOptions">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #defaultSchemaOptions="matMenu">
        <button mat-menu-item (click)="assignDefaultSchemaDialog()">Assign / Change</button>
      </mat-menu>
    </mat-card-title-group>
  </mat-card-header>
</mat-card>

<mat-card class="general-card">
  <mat-card-header>
    <mat-card-title-group>
      <mat-card-title>Your Schemas</mat-card-title>
      <mat-card-subtitle>Schemas you can use</mat-card-subtitle>
      <button mat-icon-button aria-label="options" [matMenuTriggerFor]="companySchemaOptions">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #companySchemaOptions="matMenu">
        <button mat-menu-item (click)="createNewSchemaDialog()">Create schema</button>
      </mat-menu>
    </mat-card-title-group>
  </mat-card-header>
  <ng-container *ngIf="schemas$ | async as namedSchemas">
    <mat-card-content>
      <mat-list role="list">
        <ng-container *ngFor="let namedSchema of namedSchemas">
          <mat-list-item >
            <div matListItemIcon class="button-container-fix">
              <button mat-icon-button class="no-vert-padding" aria-label="options" [matMenuTriggerFor]="schemaOptions">
                <mat-icon >more_vert</mat-icon>
              </button>
              <mat-menu #schemaOptions="matMenu">
                <button mat-menu-item (click)="viewSchemaDialog(namedSchema)">View</button>
                <button mat-menu-item (click)="editSchemaDialog(namedSchema)">Edit</button>
                <button mat-menu-item (click)="deleteSchemaDialog(namedSchema.id)">Delete</button>
              </mat-menu>
            </div>
            <div matListItemTitle>{{namedSchema.name}}</div>
          </mat-list-item>
          <mat-divider></mat-divider>
        </ng-container>
      </mat-list>
    </mat-card-content>
  </ng-container>
</mat-card>

<mat-card class="general-card">
  <mat-card-header>
    <mat-card-title-group>
      <mat-card-title>Sample schemas</mat-card-title>
      <mat-card-subtitle>Create schemas from samples</mat-card-subtitle>
    </mat-card-title-group>
  </mat-card-header>

  <ng-container *ngIf="sampleSchemas$ | async as sampleSchemas">
    <mat-card-content>
      <mat-list role="list">
        <ng-container *ngFor="let sampleSchema of sampleSchemas">
          <mat-list-item >
            <div matListItemIcon class="button-container-fix">
              <button mat-icon-button class="no-vert-padding" aria-label="options" [matMenuTriggerFor]="schemaOptions">
                <mat-icon >more_vert</mat-icon>
              </button>
              <mat-menu #schemaOptions="matMenu">
                <button mat-menu-item (click)="viewSchemaDialog(sampleSchema)">View</button>
              </mat-menu>
            </div>
            <div matListItemTitle>{{sampleSchema.name}}</div>
          </mat-list-item>
          <mat-divider></mat-divider>
        </ng-container>
      </mat-list>
    </mat-card-content>
  </ng-container>
</mat-card>
