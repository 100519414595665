import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, takeWhile } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import * as MyStore from '../../store';
import { Company } from '../../models';
import { UpsertCompanyComponent } from '../companies/upsert-company/upsert-company.component';
import { ViewCompanyComponent } from '../companies/view-company/view-company.component';
import { DeleteDialogComponent } from '../../widgets';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  alive = true;
  companiesState$: Observable<Company[]>;
  selectedCompany$: Observable<string | null>;
  companySubmitState$: Observable<MyStore.ElementState>;

  constructor(
    private store$: Store<MyStore.AppState>,
    private logger: NGXLogger,
    private dialog: MatDialog,
  ) {
    // company list
    this.companiesState$ = this.store$
      .pipe(select(MyStore.selectCompanies))
      .pipe(takeWhile(() => this.alive));

    this.selectedCompany$ = this.store$
      .pipe(select(MyStore.selectSelectedCompany))
      .pipe(takeWhile(() => this.alive));

    this.companySubmitState$ = this.store$
      .pipe(select(MyStore.selectCompanySubmit))
      .pipe(takeWhile(() => this.alive));
  }

  ngOnInit() {
    this.logger.log('DashboardComponent ngOnInit');
  }

  createNewCompanyDialog(company: Company | null): void {
    const dialogRef = this.dialog.open(UpsertCompanyComponent, {
      disableClose: true,
      data: {mode: 'new', company: company},
      width: '80vw',
      // maxWidth: '100vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  editCompanyDialog(company: Company): void {
    const dialogRef = this.dialog.open(UpsertCompanyComponent, {
      disableClose: true,
      data: {mode: 'edit', company: company},
      width: '80vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  viewCompanyDialog(company: Company): void {
    const dialogRef = this.dialog.open(ViewCompanyComponent, {
      disableClose: true,
      data: {company: company},
      width: '80vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);

      if(result == 'edit') {
        this.editCompanyDialog(company);
      } else if(result == 'duplicate') {
        this.createNewCompanyDialog(company);
      }

    });
  }

  deleteCompanyDialog(company: Company): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      disableClose: true,
      data: {title: 'Delete company', label: `Delete company by typing the "${ company.companyName }" on the field below. This process is irreversible.`, confirmString: company.companyName },
      width: '80vw',
    });

    dialogRef.componentInstance.delete$.subscribe((res) => {
      console.log('execute deletion');

      // execute deletion
      this.store$.dispatch(MyStore.deleteCompany({id: company.companyId}));

      this.companySubmitState$.subscribe((elementState) => {
        if(elementState.success) dialogRef.close();
        else if(elementState.hasFailed) {
          // TODO show error message
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

}
