import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as MyActions from '../actions';
import { CompanyService } from '../../services';
import { Company, CompanyCreate, CompanyDelete } from '../../models';

@Injectable()
export class CompanyEffects {
  constructor(
    private actions$: Actions,
    private companyService: CompanyService,
  ) { }

  getCompanies$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.getCompanies),
    switchMap(() =>
      this.companyService.getCompanies().pipe(
        map((companies: Company[]) => MyActions.getCompaniesSuccess({ 'companies': companies })),
        catchError((error) => of(MyActions.getCompaniesError(error))),
      ),
    ),
  ));

  createCompany$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.createCompany),
    switchMap((schema: { value: CompanyCreate }) =>
      this.companyService.createCompany(schema.value).pipe(
        switchMap((company: Company) => of(
          MyActions.createCompanySuccess({ "value": company}),
          MyActions.refreshTokens({ value: false }),
        )),
        catchError((error) => of(MyActions.createCompanyError(error))),
      ),
    ),
  ));

  updateCompany$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.updateCompany),
    switchMap((company: { id: string, value: CompanyCreate }) =>
      this.companyService.updateCompany(company.id, company.value).pipe(
        switchMap((company: Company) => of(
          MyActions.updateCompanySuccess({ "value": company}),
        )),
        catchError((error) => of(MyActions.updateCompanyError(error))),
      ),
    ),
  ));

  deleteCompany$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.deleteCompany),
    switchMap((companyDelete: CompanyDelete) =>
      this.companyService.deleteCompany(companyDelete.id).pipe(
        switchMap((companyDeleted: CompanyDelete) => of(
          MyActions.deleteCompanySuccess({ "id": companyDeleted.id}),
          MyActions.refreshTokens({ value: false }),
        )),
        catchError((error) => of(MyActions.deleteCompanyError(error))),
      ),
    ),
  ));

}
