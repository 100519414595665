import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Store, select } from '@ngrx/store';
import { Observable, take, takeWhile } from 'rxjs';

import { FlatTreeControl } from '@angular/cdk/tree';
import { MatDialog } from '@angular/material/dialog';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';

import * as MyStore from '../../store';
import { CategoryBranch, CategoryInfo, NamedSchemaObj, Product, ProductCreateObj, PropertyCreateObj, PropertyObj, TreeFlatNode } from '../../models';
import { AssignSchemaComponent } from '../schemas/assign-schema/assign-schema.component';
import { UpsertCategoryComponent } from './categories/upsert-category/upsert-category.component';
import { DeleteDialogComponent } from '../../widgets';
import { UpsertProductComponent } from './upsert-product/upsert-product.component';
import { CustomSaveDialogComponent } from 'src/app/widgets/custom-save-dialog/custom-save-dialog.component';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit, OnDestroy {
  alive = true;
  categoriesState$: Observable<CategoryBranch[]>;
  categoryPropertiesState$: Observable<PropertyObj[]>;
  categoryBequeathPropertiesState$: Observable<PropertyObj[]>;
  productsState$: Observable<Product[]>;
  selectedCategoryState$: Observable<CategoryInfo>;
  selectedCategorySchema$: Observable<NamedSchemaObj | null>;
  productSubmitState$: Observable<MyStore.ElementState>;

  _transformer = (node: CategoryBranch, level: number) => {
    return {
      expandable: !!node.categories && node.categories.length > 0,
      id: node.id,
      name: node.name,
      level: level,
    };
  };
  treeControl = new FlatTreeControl<TreeFlatNode>(
    node => node.level,
    node => node.expandable,
  );
  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.categories,
  );
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  hasChild = (_: number, node: TreeFlatNode) => node.expandable;

  constructor(
    private logger: NGXLogger,
    private store$: Store<MyStore.AppState>,
    private dialog: MatDialog,
  ) {
    this.logger.log('ProductsComponent constructor');

    this.categoriesState$ = this.store$
      .pipe(select(MyStore.selectCategories))
      .pipe(takeWhile(() => this.alive));

    this.categoryPropertiesState$ = this.store$
      .pipe(select(MyStore.selectCategoryProperties))
      .pipe(takeWhile(() => this.alive));

    this.categoryBequeathPropertiesState$ = this.store$
      .pipe(select(MyStore.selectCategoryBequeathProperties))
      .pipe(takeWhile(() => this.alive));

    this.productsState$ = this.store$
      .pipe(select(MyStore.selectProducts))
      .pipe(takeWhile(() => this.alive));

    this.selectedCategoryState$ = this.store$
      .pipe(select(MyStore.selectSelectedCategory))
      .pipe(takeWhile(() => this.alive));

    this.selectedCategorySchema$ = this.store$
      .pipe(select(MyStore.selectSelectedCategorySchema))
      .pipe(takeWhile(() => this.alive));

    this.productSubmitState$ = this.store$
      .pipe(select(MyStore.selectProductSubmit))
      .pipe(takeWhile(() => this.alive));
  }

  ngOnInit(): void {
    this.logger.log('ProductsComponent ngOnInit');

    this.store$.dispatch(MyStore.getCategories());

    this.categoriesState$.subscribe((categories: CategoryBranch[]) => {
      this.logger.log('ProductsComponent this.categoriesState$.subscribe');
      this.dataSource.data = JSON.parse(JSON.stringify(categories));
      this.treeControl.expandAll();
    });
  }

  selectCategory(node: any, isLeaf: boolean): void {
    this.logger.log('ProductsComponent selectCategory', node, isLeaf);

    const categoryInfo: CategoryInfo = {
      id: node.id,
      name: node.name,
      isLeaf: isLeaf,
      level: node.level,
    }
    this.store$.dispatch(MyStore.selectCategory({value: categoryInfo}));
    this.store$.dispatch(MyStore.getProductsInCategories({value: categoryInfo.id}));
    this.store$.dispatch(MyStore.getCategorySchema({value: categoryInfo.id}));
    this.store$.dispatch(MyStore.getCategoryProperties({value: categoryInfo.id}));
  }

  unselectCategory(): void {
    this.logger.log('ProductsComponent unselectCategory');
    this.store$.dispatch(MyStore.unselectCategory());
  }

  assignCategorySchema(category: CategoryBranch): void {
    this.logger.log('ProductsComponent assignCategoryNamedSchema', category);

    const dialogRef = this.dialog.open(AssignSchemaComponent, {
      data: {mode: 'category', category},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  addCategory(refId: string | undefined, relation: string | undefined): void {
    this.logger.log('ProductsComponent addCategory');

    const dialogRef = this.dialog.open(UpsertCategoryComponent, {
      data: {mode: 'new', id: null, name: null, refId: refId, relation: relation },
      width: '80vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed',result);
    });
  }

  editCategory(id: string, name: string): void {
    this.logger.log('ProductsComponent editCategory');

    const dialogRef = this.dialog.open(UpsertCategoryComponent, {
      data: {mode: 'edit', id: id, name: name, refId: undefined, relation: undefined},
      width: '80vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed',result);
    });
  }

  deleteCategory(id: string, name: string): void {
    this.logger.log('ProductsComponent deleteCategory');
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      disableClose: true,
      data: {title: 'Delete category', label: `Delete category by typing the "${ name }" on the field below. This process is irreversible.`, confirmString: name },
      width: '80vw',
    });

    dialogRef.componentInstance.delete$.subscribe((res) => {
      console.log('execute deletion');

      // execute deletion
      this.store$.dispatch(MyStore.deleteCategory({id: id}));

      this.productSubmitState$.subscribe((elementState) => {
        if(elementState.success) dialogRef.close();
        else if(elementState.hasFailed) {
          // TODO show error message
        }
      });
    });
  }

  createProduct(category: CategoryBranch): void {
    this.logger.log('ProductsComponent createProduct', category);

    const dialogRef = this.dialog.open(UpsertProductComponent, {
      disableClose: true,
      data: {mode: 'new'},
      width: '80vw',
    });

    dialogRef.componentInstance.save$.subscribe((res) => {
      this.logger.log('ProductsComponent createProduct saving', res);

      this.store$
      .pipe(select(MyStore.selectSelectedCategory))
      .pipe(take(1))
      .subscribe((category: CategoryBranch) => {
        this.store$.dispatch(MyStore.createProduct({value: res as ProductCreateObj, categoryId: category.id}));

        this.productSubmitState$.subscribe((elementState) => {
          if(elementState.success) dialogRef.close();
          else if(elementState.hasFailed) {
            // TODO show error message
          }
        });
      });
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  editProduct(product: Product): void {
    this.logger.log('ProductsComponent editProduct', product);

    const dialogRef = this.dialog.open(UpsertProductComponent, {
      disableClose: true,
      data: {mode: 'edit', product: product},
      width: '80vw',
    });

    dialogRef.componentInstance.save$.subscribe((res) => {
      this.logger.log('ProductsComponent editProduct saving', res);

      this.store$.dispatch(MyStore.updateProduct({value: res as ProductCreateObj, productId: product.id}));

      this.productSubmitState$.subscribe((elementState) => {
        if(elementState.success) dialogRef.close();
        else if(elementState.hasFailed) {
          // TODO show error message
        }
      });
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  viewProduct(product: Product): void {
    this.logger.log('ProductsComponent viewProduct', product);

    const dialogRef = this.dialog.open(UpsertProductComponent, {
      disableClose: true,
      data: {mode: 'view', product: product},
      width: '80vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('result', result);
      if(result) {
        this.duplicateProduct(result as Product);
      }
    });
  }

  duplicateProduct(product: Product): void {
    this.logger.log('ProductsComponent duplicateProduct', product);

    const dialogRef = this.dialog.open(UpsertProductComponent, {
      disableClose: true,
      data: {mode: 'new', product: product},
      width: '80vw',
    });

    dialogRef.componentInstance.save$.subscribe((res) => {
      this.logger.log('ProductsComponent createProduct saving', res);

      this.store$
      .pipe(select(MyStore.selectSelectedCategory))
      .pipe(take(1))
      .subscribe((category: CategoryBranch) => {
        this.store$.dispatch(MyStore.createProduct({value: res as ProductCreateObj, categoryId: category.id}));

        this.productSubmitState$.subscribe((elementState) => {
          if(elementState.success) dialogRef.close();
          else if(elementState.hasFailed) {
            // TODO show error message
          }
        });
      });
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  deleteProduct(product: Product): void {
    this.logger.log('ProductsComponent deleteProduct', product);

    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      disableClose: true,
      data: {title: 'Delete product', label: `Delete product by typing the "${ product.label }" on the field below. This process is irreversible.`, confirmString: product.label },
      width: '80vw',
    });

    dialogRef.componentInstance.delete$.subscribe((res) => {
      console.log('execute deletion');

      // execute deletion
      this.store$.dispatch(MyStore.deleteProduct({id: product.id}));

      this.productSubmitState$.subscribe((elementState) => {
        if(elementState.success) dialogRef.close();
        else if(elementState.hasFailed) {
          // TODO show error message
        }
      });
    });
  }

  addCategoryProperty(type: string, categoryId: string, referenceType: string): void {
    this.logger.log('ProductsComponent addCategoryProperty', type);

    const schema: any = this.getCategoryPropertySchema(type);

    const dialogRef = this.dialog.open(CustomSaveDialogComponent, {
      disableClose: true,
      data: {title: 'Add property', schema: schema},
      width: '80vw',
    });

    dialogRef.componentInstance.save$.subscribe((res) => {
      console.log('save property',res);

      const propertyCreateObj: PropertyCreateObj = {
        type: type,
        key: res.key,
        value: res.value,
        referenceType: referenceType,
      }

      // execute save
      this.store$.dispatch(MyStore.createCategoryProperties({value: propertyCreateObj, categoryId: categoryId}));

      this.productSubmitState$.subscribe((elementState) => {
        if(elementState.success) dialogRef.close();
        else if(elementState.hasFailed) {
          // TODO show error message
        }
      });
    });
  }

  editCategoryProperty(propertyObj: PropertyObj, referenceType: string): void {
    this.logger.log('ProductsComponent editCategoryProperty', propertyObj);

    const schema: any = this.getCategoryPropertySchema(propertyObj.type);

    const dialogRef = this.dialog.open(CustomSaveDialogComponent, {
      disableClose: true,
      data: {title: 'Edit property', schema: schema, data: propertyObj},
      width: '80vw',
    });

    dialogRef.componentInstance.save$.subscribe((res) => {
      console.log('save property',res);

      const propertyUpdateObj: PropertyCreateObj = {
        type: propertyObj.type,
        key: res.key,
        value: res.value,
        referenceType: referenceType,
      }

      // execute save
      this.store$.dispatch(MyStore.updateCategoryProperty({value: propertyUpdateObj, propertyId: propertyObj.id}));

      this.productSubmitState$.subscribe((elementState) => {
        if(elementState.success) dialogRef.close();
        else if(elementState.hasFailed) {
          // TODO show error message
        }
      });
    });
  }

  deleteCategoryProperty(propertyObj: PropertyObj): void {
    this.logger.log('ProductsComponent deleteCategoryProperty', propertyObj);

    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      disableClose: true,
      data: {title: 'Delete property', label: `Delete property by typing the "${ propertyObj.key }" on the field below. This process is irreversible.`, confirmString: propertyObj.key },
      width: '80vw',
    });

    dialogRef.componentInstance.delete$.subscribe((res) => {
      console.log('execute deletion');

      // execute deletion
      this.store$.dispatch(MyStore.deleteCategoryProperty({id: propertyObj.id}));

      this.productSubmitState$.subscribe((elementState) => {
        if(elementState.success) dialogRef.close();
        else if(elementState.hasFailed) {
          // TODO show error message
        }
      });
    });
  }

  viewCategoryProperty(propertyObj: PropertyObj): void {
    this.logger.log('ProductsComponent viewCategoryProperty', propertyObj);

    const schema: any = this.getCategoryPropertySchema(propertyObj.type);

    const dialogRef = this.dialog.open(CustomSaveDialogComponent, {
      disableClose: true,
      data: {title: 'View property', schema: schema, data: propertyObj, mode: 'view'},
      width: '80vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('result', result);
      if(result) {
        this.duplicateProduct(result as Product);
      }
    });
  }

  getCategoryPropertySchema(type: string) {
    let schema: any = {
      type: "object",
      layout: "horizontal",
      required: [
        "key",
        "value"
      ],
      properties: {
        key: {
          type: "string"
        },
        value: {
          type: "string"
        }
      }
    };
    if(type == 'long text') {
      schema.properties.value.widget = 'textarea';
      schema.properties.value.style = {width: "100%"};
      schema.layout = 'vertical';
    } else if(type == 'boolean') {
      schema.properties.value.type = 'boolean';
    } else if(type == 'integer') {
      schema.properties.value.type = 'integer';
    } else if(type == 'number') {
      schema.properties.value.type = 'number';
    } else if(type == 'date') {
      schema.properties.value.widget = 'date';
    } else if(type == 'time') {
      schema.properties.value.widget = 'time';
    } else if(type == 'date-time') {
      schema.properties.value.widget = 'datetime-local';
    }

    return schema;
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

}
