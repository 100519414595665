<mat-sidenav-container id="container" fullscreen>
  <mat-sidenav #sidenav>
    <mat-nav-list>
      <a mat-list-item routerLink="/dashboard">Dashboard</a>
      <a mat-list-item routerLink="/products">Products</a>
      <a mat-list-item routerLink="/schemas">Schemas</a>
      <a mat-list-item routerLink="/product-options">Product Options</a>
      <a mat-list-item>Assets</a>
    </mat-nav-list>
    <mat-divider></mat-divider>
    <ng-container *ngIf="{selectedCompany: selectedCompany$ | async, companies: companiesState$ | async} as data">
      <mat-nav-list *ngIf="data.companies as companies">
        <a mat-list-item *ngFor="let company of companies" (click)="changeSelectedCompany(company.companyId, data.selectedCompany)" [ngClass]="{'highlist-list-item': data.selectedCompany === company.companyId}">{{ company.companyName }}</a>
      </mat-nav-list>
    </ng-container>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <mat-toolbar-row>
        <button mat-icon-button (click)="sidenav.toggle();">
          <mat-icon>menu</mat-icon>
        </button>
        <span>Stall Owner</span>
      </mat-toolbar-row>
    </mat-toolbar>
    <div>
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
