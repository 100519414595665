import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import jwtDecode from 'jwt-decode';

import { ElementState, AuthState } from '../states';
import { Tokens } from '../../models';

const getToken = (state: AuthState) => state.tokens;
const getSubmit = (state: AuthState) => state.submit;
const getAccessData = (state: AuthState) => {
  if (state.tokens != null && state.tokens['access-token'] != null) {
    return jwtDecode(state.tokens['access-token']);
  }
  return null;
};
const getRefreshToken = (state: AuthState) => state.tokens['refresh-token'];
const getAccessToken = (state: AuthState) => state.tokens['access-token'];
const getCognitoIdToken = (state: AuthState) => state.tokens['cognito-id-token'];
const getCognitoAccessToken = (state: AuthState) => state.tokens['cognito-access-token'];

export const selectAuthState: MemoizedSelector<object, AuthState> = createFeatureSelector<AuthState>('auth');

export const selectToken: MemoizedSelector<object, Tokens> = createSelector(
  selectAuthState,
  getToken,
);

export const selectSubmit: MemoizedSelector<object, ElementState> = createSelector(
  selectAuthState,
  getSubmit,
);

export const selectAccessData: MemoizedSelector<object, any> = createSelector(
  selectAuthState,
  getAccessData,
);

export const selectRefreshToken: MemoizedSelector<object, any> = createSelector(
  selectAuthState,
  getRefreshToken,
);

export const selectAccessToken: MemoizedSelector<object, any> = createSelector(
  selectAuthState,
  getAccessToken,
);

export const selectCognitoIdToken: MemoizedSelector<object, any> = createSelector(
  selectAuthState,
  getCognitoIdToken,
);

export const selectCognitoAccessToken: MemoizedSelector<object, any> = createSelector(
  selectAuthState,
  getCognitoAccessToken,
);

