<h2 mat-dialog-title>
  <ng-container *ngIf="selectedCategoryState$ | async as category">
    <ng-container *ngIf="category.id != ''">
      {{title}}
    </ng-container>
  </ng-container>
</h2>
<mat-dialog-content class="mat-typography">
  <ng-container *ngIf="labelForm">
    <form [formGroup]="labelForm" (ngSubmit)="$event.preventDefault()">
      <mat-form-field>
        <mat-label>Label</mat-label>
        <input matInput cdkFocusInitial formControlName="label">
      </mat-form-field>
    </form>
  </ng-container>
  <ng-container *ngIf="displayForm" (ngSubmit)="$event.preventDefault()">
    <lib-json-schema-form [state]="state"></lib-json-schema-form>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button *ngIf="(data.mode == 'view')" (click)="duplicate()">Duplicate</button>
  <button mat-button *ngIf="displayForm && (data.mode != 'view')" [disabled]="formStatus!='VALID' || !labelForm.valid" (click)="save()">Save</button>
</mat-dialog-actions>
