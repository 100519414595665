import { Address, Company } from '../../models';
import { ElementState } from './element.state';

export interface CompanyState {
  readonly selected: string | null;
  readonly companies: Company[];
  readonly addresses: Address[];
  readonly submit: ElementState;
}

export const initialCompanyState: CompanyState = {
  selected: null,
  companies: [],
  addresses: [],
  submit: new ElementState(),
};
