import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';

import { User } from '../models';
import { environment } from '../../environments/environment';
import { AppSettings } from '../app.settings';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
  ) {
    this.logger.log('UserService constructor');
  }

  /**
   * Calls a getUser http request
   * @return {Observable<User>}
   */
  getUser(): Observable<User> {
    this.logger.log('UserService getUser');
    const apiPath = AppSettings.pathUsers;
    return this.http.get<User>(environment.apiUrl + apiPath);
  }

  /**
   * After successful getUser
   * @param {User} user
   */
  getUserSuccess(user: User): void {
    this.logger.log('UserService getUserSuccess');
    this.logger.log(user);
  }
}
