import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { DatePipe } from '@angular/common';

import * as MyStore from '../../../store';
import { Company } from '../../../models';
import { AppSettings } from '../../../app.settings';
import { Observable, takeWhile } from 'rxjs';

@Component({
  selector: 'app-view-company',
  templateUrl: './view-company.component.html',
  styleUrls: ['./view-company.component.scss']
})
export class ViewCompanyComponent implements OnInit, OnDestroy {
  alive = true;
  title: string;
  form: FormGroup;

  constructor(
    private logger: NGXLogger,
    private store$: Store<MyStore.AppState>,
    private dialogRef: MatDialogRef<any>,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) private data: {company: Company },
  ) {
    this.logger.log('ViewCompanyComponent constructor', data);

    this.form = this.fb.group ({
      companyName: [data.company.companyName],
      nameId: [data.company.nameId],
      description: [data.company.description],
      createdBy: [data.company.createdBy],
      updatedBy: [data.company.updatedBy],
      dateCreated: [datePipe.transform(data.company.dateCreated,'medium')],
      dateUpdated: [datePipe.transform(data.company.dateCreated,'medium')],
      active: [data.company.active],
    });

    this.title = 'View company';
  }

  ngOnInit() {
    this.logger.log('ViewCompanyComponent ngOnInit');
  }

  edit(): void {
    this.logger.log('ViewCompanyComponent edit');
    this.dialogRef.close('edit');
  }

  duplicate(): void {
    this.logger.log('ViewCompanyComponent duplicate');
    this.dialogRef.close('duplicate');
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
}
