import { createAction, props } from '@ngrx/store';
import { Company, CompanyCreate, CompanyDelete } from 'src/app/models';

export const getCompanies = createAction('[Company] GETCOMPANIES');
export const getCompaniesSuccess = createAction('[Company] GETCOMPANIES_SUCCESS', (companyList: { companies: Company[] }) => companyList);
export const getCompaniesError = createAction('[Company] GETCOMPANIES_ERROR', (error: any) => error);

export const selectCompany = createAction('[Company] SELECTCOMPANY', (companyId: { value: string }) => companyId);

export const createCompany = createAction('[Company] CREATECOMPANY', (company: { value: CompanyCreate }) => company);
export const createCompanySuccess = createAction('[Company] CREATECOMPANY_SUCCESS', (company: { value: Company }) => company);
export const createCompanyError = createAction('[Company] CREATECOMPANY_ERROR', (error: any) => error);

export const updateCompany = createAction('[Company] UPDATECOMPANY', (company: { id: string, value: CompanyCreate }) => company);
export const updateCompanySuccess = createAction('[Company] UPDATECOMPANY_SUCCESS', (company: { value: Company }) => company);
export const updateCompanyError = createAction('[Company] UPDATECOMPANY_ERROR', (error: any) => error);

export const deleteCompany = createAction('[Company] DELETECOMPANY', (companyDelete: CompanyDelete) => companyDelete);
export const deleteCompanySuccess = createAction('[Company] DELETECOMPANY_SUCCESS', (companyDelete: CompanyDelete) => companyDelete);
export const deleteCompanyError = createAction('[Company] DELETECOMPANY_ERROR', (error: any) => error);
