import { createAction } from '@ngrx/store';
import { Tokens, TokensForAccess } from 'src/app/models';

export const getTokens = createAction('[Auth] GETTOKENS');
export const getTokensSuccess = createAction('[Auth] GETTOKENS_SUCCESS', (tokens: Tokens) => tokens);
export const getTokensError = createAction('[Auth] GETTOKENS_ERROR', (error: any) => error);

export const reloadRefreshToken = createAction('[Auth] RELOADREFRESHTOKEN', (refreshToken: { value: string }) => refreshToken);

export const refreshTokens = createAction('[Auth] REFRESHTOKENS', (fetchData: { value: boolean }) => fetchData);
export const refreshTokensSuccess = createAction('[Auth] REFRESHTOKENS_SUCCESS', (tokensForAccess: { value: TokensForAccess, fetchData: boolean }) => tokensForAccess);
export const refreshTokensError = createAction('[Auth] REFRESHTOKENS_ERROR', (error: any) => error);

export const logout = createAction('[Auth] LOGOUT');
export const logoutSuccess = createAction('[Auth] LOGOUT_SUCCESS');
export const logoutError = createAction('[Auth] LOGOUT_ERROR', (error: any) => error);
