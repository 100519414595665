import { User } from '../../models';
import { ElementState } from './element.state';

export interface UserState {
  readonly user: User;
  readonly submit: ElementState;
}

export const initialUserState: UserState = {
  user: {
    UserCreateDate: null,
    UserLastModifiedDate: null,
    Enabled: null,
    UserStatus: null,
    UserAttributes: [],
  },
  submit: new ElementState(),
};
