import { Country, Currency, Province } from '../../models';
import { ElementState } from './element.state';

export interface RefState {
  countries: Country[];
  provinces: Province[];
  currencies: Currency[];
  submit: ElementState;
}

export const initialRefState: RefState = {
  countries: [],
  provinces: [],
  currencies: [],
  submit: new ElementState(),
};
