export class AppSettings {
  // regex
  public static regexNumeric = '^[0-9]*$';
  public static regexMoney = '^[0-9]*\\.?[0-9]{0,2}$';
  public static regexStock = '^[0-9]*\\.?[0-9]{0,4}$';
  public static regexAlpha = '^[a-zA-Z]*$';
  public static regexAlphaLowercase = '^[a-z]*$';
  public static regexAlphaUppercase = '^[A-Z]*$';
  public static regexAlphaNumeric = '^[a-zA-Z0-9]*$';
  public static regexAlphaNumericLowercase = '^[a-z0-9]*$';
  public static regexAlphaNumericDash = '^(?!-)(?!.*--)[A-Za-z0-9-]*(?<!-)$';
  public static regexAlphaNumericLowercaseDash = '^(?!-)(?!.*--)[a-z0-9-]*(?<!-)$';
  public static regexAlphaNumericSpace = '^(?! )(?!.*  )[A-Za-z0-9 ]*(?<! )$';
  public static regexAlphaNumericDashSpace = '^(?! )(?!-)(?!.*  )(?!.*--)[A-Za-z0-9- ]*(?<! )(?<!-)$';
  public static regexLimited = '^(?! )(?!-)(?!.*  )(?!.*--)[A-Za-z0-9-~`!@#$%^&*\\(\\)_=+\\\\|:;"\\\',\\\./? ]*(?<! )(?<!-)$';
  public static regexLimitedForSearch = '^[A-Za-z0-9-~`!@#$%^&*\\(\\)_=+\\\\|:;"\\\',\\\./? ]*$';
  public static regexComplete = '^(?! )(?!-)(?!.*  )(?!.*--)[A-Za-z0-9-~`!@#$%^&*\\(\\)_=+\\\\|:;"\\\',\\\./?\\\<\\\>\\\[\\\]\\\{\\\} ]*(?<! )(?<!-)$';
  public static regexCompleteNoSpace = '^[A-Za-z0-9-~`!@#$%^&*\\(\\)_=+\\\\|:;"\\\',\\\./?\\\<\\\>\\\[\\\]\\\{\\\}]*$';
  public static regexEverythingNoBackslash = '^(?![-\s])(?!.*\s\s)(?!.*--)[^\\]*(?<![-\s])$';
  public static regexUuid = '^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$';
  public static regexEmail = '^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';
  public static regexZipCode = '^[1-9]{1}[0-9]{3}$';
  public static regexCountryCode = '^[1-9]{1}[0-9]{0,2}$';

  // service paths
  public static pathTokensRevoke = '/tokens/revoke';
  public static pathTokensRefresh = '/tokens/refresh';
  public static pathTokensCode = '/tokens/code';
  public static pathUsers = '/users';
  public static pathCompanies = '/companies';
  public static pathProducts = '/products';
  public static pathProductsCategories = '/products/categories';
  public static pathProductsPropertiesId = '/products/properties/id/';
  public static pathProductsPropertiesCategories = '/products/properties/categories/id/';
  public static pathProductsOptions = '/products/options';
  public static pathProductsSchemas = '/products/schemas';
  public static pathProductsSchemasCategories = '/products/schemas/categories/id/';
  public static pathProductsSchemasDefault = '/products/schemas/default';
  public static pathProductsSchemasSamples = '/products/schemas/samples';
}
