// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://devapi.stall.ph',
  apiVersion: '1.0',
  cognito: {
    domain: 'https://devauth.stall.ph',
    clientId: '1gf0p0pq9n5kru637ekiu0i14e',
    responseType: 'code',
    scope: 'email+openid+phone',
    redirectUri: 'https://owner-dev.stall.ph/loggedin',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
