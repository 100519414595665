import { createReducer, on } from '@ngrx/store';
import { getUser, getUserSuccess, getUserError, removeUser } from '../actions';
import { ElementState, initialUserState } from '../states';

export const userReducer = createReducer(
  initialUserState,
  on(getUser, (state) => {
    return {
      ...state,
      submit: ElementState.create(true),
    };
  }),
  on(getUserSuccess, (state, user) => {
    return {
      ...state,
      submit: ElementState.success('Success'),
      user: user,
    };
  }),
  on(getUserError, (state, error) => {
    return {
      ...state,
      submit: ElementState.error(error),
    };
  }),
  on(removeUser, (state, error) => {
    return {
      ...state,
      submit: new ElementState(),
    };
  }),
);
