import { Component, OnDestroy, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  alive = true;
  constructor(
    private logger: NGXLogger,
  ) {
  }

  ngOnInit() {
    this.logger.log('LoginPage ngOnInit');

    let loginUrl = environment.cognito.domain + '/login?';
    loginUrl += 'client_id=' + environment.cognito.clientId;
    loginUrl += '&response_type=' + environment.cognito.responseType;
    loginUrl += '&scope=' + environment.cognito.scope;
    loginUrl += '&redirect_uri=' + encodeURIComponent(environment.cognito.redirectUri);
    window.location.href = loginUrl;
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

}
