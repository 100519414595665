import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Observable, takeWhile } from 'rxjs';

import * as MyStore from '../../../../store';
import { CategoryUpsert, Company, CompanyCreate } from '../../../../models';
import { AppSettings } from '../../../../app.settings';

@Component({
  selector: 'app-upsert-category',
  templateUrl: './upsert-category.component.html',
  styleUrls: ['./upsert-category.component.scss']
})
export class UpsertCategoryComponent implements OnInit, OnDestroy {
  alive = true;
  title: string;
  form: FormGroup;
  productSubmitState$: Observable<MyStore.ElementState>;

  constructor(
    private logger: NGXLogger,
    private store$: Store<MyStore.AppState>,
    private dialogRef: MatDialogRef<any>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: {mode: string, id: string, name: string, refId: string | undefined, relation: string | undefined}, // new, edit
  ) {
    this.logger.log('UpsertCategoryComponent constructor', data);

    if(data.mode == 'new') {
      this.title = 'New category';
    } else {
      this.title = 'Edit category';
    }

    if(data.id == null) {
      this.form = this.fb.group ({
        name: ['', [Validators.required, Validators.pattern(AppSettings.regexComplete)]],
      });
    } else {
      this.form = this.fb.group ({
        name: [data.name, [Validators.required, Validators.pattern(AppSettings.regexComplete)]],
      });
    }

    this.productSubmitState$ = this.store$
      .pipe(select(MyStore.selectProductSubmit))
      .pipe(takeWhile(() => this.alive));
  }

  ngOnInit() {
    this.logger.log('UpsertCategoryComponent ngOnInit');
  }

  save(): void {
    this.logger.log('UpsertCategoryComponent save', this.form.value);
    if(!this.form.valid) {
      return;
    }

    const categoryUpsert: CategoryUpsert = {
      name: this.form.value.name,
      refId: this.data.refId,
      relation: this.data.relation,
    }
    if(this.data.mode == 'new') {
      this.store$.dispatch(MyStore.addCategory({value: categoryUpsert}));
      this.productSubmitState$.subscribe((elementState) => {
        if(elementState.success) this.dialogRef.close();
      });
    } else if(this.data.mode == 'edit') {
      this.store$.dispatch(MyStore.editCategory({id: this.data.id, value: categoryUpsert}));
      this.productSubmitState$.subscribe((elementState) => {
        if(elementState.success) this.dialogRef.close();
      });
    }
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

}
