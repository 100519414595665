import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';

import { SelectOptions, SelectOptionsCreate } from '../models';
import { environment } from '../../environments/environment';
import { AppSettings } from '../app.settings';

@Injectable({
  providedIn: 'root',
})
export class SelectOptionsService {
  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
  ) {
    this.logger.log('SelectOptionsService constructor');
  }

  /**
   * Calls a getSchemaOfCategory http request
   * @return {Observable<NamedSchemaObj[]>}
   */
  getSelectOptions(pageSize: number | null, pageOffset: number | null, includeTotal: boolean | null, search: string | null): Observable<SelectOptions[]> {
    this.logger.log('SelectOptionsService getOptions');
    const apiPath = AppSettings.pathProductsOptions;
    if(pageSize != null || pageOffset != null || includeTotal != null  || search != null) {
      let params = new HttpParams();
      if(pageSize) params = params.append('pageSize', pageSize);
      if(pageOffset) params = params.append('pageOffset', pageOffset);
      if(includeTotal) params = params.append('includeTotal', includeTotal);
      if(search) params = params.append('s', search);
      return this.http.get<SelectOptions[]>(environment.apiUrl + apiPath, { params: params });
    }
    return this.http.get<SelectOptions[]>(environment.apiUrl + apiPath);
  }

  /**
   * Calls a createOptions http request
   * @return {Observable<SelectOptions>}
   */
  createOptions(selectOptions: SelectOptionsCreate): Observable<SelectOptions> {
    this.logger.log('SelectOptionsService createOptions');
    const apiPath = AppSettings.pathProductsOptions;
    return this.http.post<SelectOptions>(environment.apiUrl + apiPath, selectOptions);
  }

  /**
   * Calls a updateSelectOptions http request
   * @return {Observable<SelectOptions>}
   */
  updateSelectOptions(optionsId: string, selectOptions: SelectOptionsCreate): Observable<SelectOptions> {
    this.logger.log('SelectOptionsService createSchema');
    const apiPath = AppSettings.pathProductsOptions + '/id/' + optionsId;
    return this.http.put<SelectOptions>(environment.apiUrl + apiPath, selectOptions);
  }

  /**
   * Calls a deleteSelectOptions http request
   * @return {Observable<any>}
   */
  deleteSelectOptions(optionsId: string): Observable<any> {
    this.logger.log('SelectOptionsService deleteSelectOptions');
    const apiPath = AppSettings.pathProductsOptions + '/id/' + optionsId;
    return this.http.delete<any>(environment.apiUrl + apiPath);
  }
}
