import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Schema, State } from '@dashjoin/json-schema-form';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-custom-save-dialog',
  templateUrl: './custom-save-dialog.component.html',
  styleUrls: ['./custom-save-dialog.component.scss']
})
export class CustomSaveDialogComponent implements OnInit, OnDestroy {
  alive = true;
  @Output() save$ = new EventEmitter<any>();

  formValue = null;
  formStatus: string = 'INVALID';
  displayForm: boolean = false;
  state: State = {
    schema: {
      type: "object",
      required: ["Loading"],
      properties: {
        Loading: {
          type: "string"
        },
      },
    },
    name: 'Loading',
    value: {},
    control: new FormGroup({})
  };

  constructor(
    private logger: NGXLogger,
    @Inject(MAT_DIALOG_DATA) public data: {title: string, schema: Schema, data: any, mode: string}, // new, edit, view
  ) {
    this.logger.log('CustomSaveDialogComponent constructor', data);
  }

  ngOnInit() {
    this.logger.log('CustomSaveDialogComponent ngOnInit');

    this.createStateFromSchema();
  }

  createStateFromSchema() {
    this.logger.log('CustomSaveDialogComponent createStateFromSchema');
    this.state = {
      schema: this.data.schema,
      name: 'test',
      value: (this.data.data)? this.data.data : undefined,
      control: (this.data.schema.type == 'object')? new FormGroup({}) : (this.data.schema.type == 'array')? new FormArray([]) : new FormControl(),
    }

    this.logger.log('CustomSaveDialogComponent createStateFrom state', this.state);
    this.state.control.valueChanges.subscribe((res: any) => {
      this.formValue = res;
    });
    setTimeout(() => {
      this.state.control.statusChanges.subscribe((status: string) => {
        this.formStatus = status;
      });
    }, 1);
    this.displayForm = true;
  }

  save(): void {
    this.logger.log('CustomSaveDialogComponent save', this.formValue);
    this.save$.emit(this.formValue);
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
}
