import { ElementState, ProductState } from '../states';
import { Product, CategoryBranch, NamedSchemaObj, CategoryInfo, PropertyObj } from '../../models';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

const getProducts = (state: ProductState) => state.products;
const getProductSubmit = (state: ProductState) => state.submit;
const getCategories = (state: ProductState) => state.categories;
const getCategoryProperties = (state: ProductState) => state.selectedCategoryProperties.filter((property:PropertyObj) => property.referenceType == 'category');
const getCategoryBequeathProperties = (state: ProductState) => state.selectedCategoryProperties.filter((property:PropertyObj) => property.referenceType == 'bequeath');
const getSelectedCategory = (state: ProductState) => state.selectedCategory;
const getSelectedCategorySchema = (state: ProductState) => state.selectedCategorySchema;
const getSchemas = (state: ProductState) => state.schemas;
const getDefaultSchema = (state: ProductState) => state.defaultSchema;
const getSampleSchemas = (state: ProductState) => state.sampleSchemas;

export const selectProductState: MemoizedSelector<object, ProductState> = createFeatureSelector<ProductState>('products');

export const selectProducts: MemoizedSelector<object, Product[]> = createSelector(
  selectProductState,
  getProducts,
);

export const selectProductSubmit: MemoizedSelector<object, ElementState> = createSelector(
  selectProductState,
  getProductSubmit,
);

export const selectCategories: MemoizedSelector<object, CategoryBranch[]> = createSelector(
  selectProductState,
  getCategories,
);

export const selectCategoryProperties: MemoizedSelector<object, PropertyObj[]> = createSelector(
  selectProductState,
  getCategoryProperties,
);

export const selectCategoryBequeathProperties: MemoizedSelector<object, PropertyObj[]> = createSelector(
  selectProductState,
  getCategoryBequeathProperties,
);

export const selectSelectedCategory: MemoizedSelector<object, CategoryInfo> = createSelector(
  selectProductState,
  getSelectedCategory,
);

export const selectSelectedCategorySchema: MemoizedSelector<object, NamedSchemaObj | null> = createSelector(
  selectProductState,
  getSelectedCategorySchema,
);

export const selectSchemas: MemoizedSelector<object, NamedSchemaObj[]> = createSelector(
  selectProductState,
  getSchemas,
);

export const selectDefaultSchema: MemoizedSelector<object, NamedSchemaObj | null> = createSelector(
  selectProductState,
  getDefaultSchema,
);

export const selectSampleSchemas: MemoizedSelector<object, NamedSchemaObj[]> = createSelector(
  selectProductState,
  getSampleSchemas,
);
