import { Component, OnDestroy, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

import * as MyStore from '../../store';
import { Store, select } from '@ngrx/store';
import { SelectOptions } from 'src/app/models';
import { Observable, takeWhile } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { UpsertOptionsComponent } from './upsert-options/upsert-options.component';
import { DeleteDialogComponent } from 'src/app/widgets';

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss']
})
export class OptionsComponent implements OnInit, OnDestroy {
  alive = true;
  selectOptions$: Observable<SelectOptions[]>;
  selectOptionsSubmit$: Observable<MyStore.ElementState>;

  constructor(
    private logger: NGXLogger,
    private store$: Store<MyStore.AppState>,
    private dialog: MatDialog,
  ) {
    this.logger.log('OptionsComponent constructor');

    this.selectOptions$ = this.store$
      .pipe(select(MyStore.selectSelectOptions))
      .pipe(takeWhile(() => this.alive));

    this.selectOptionsSubmit$ = this.store$
      .pipe(select(MyStore.selectSelectOptionsSubmit))
      .pipe(takeWhile(() => this.alive));
  }

  ngOnInit(): void {
    this.logger.log('OptionsComponent ngOnInit');

    this.store$.dispatch(MyStore.getSelectOptions({pageSize: null,pageOffset: null,includeTotal: null}));
  }

  createOptions(): void {
    this.logger.log('OptionsComponent createOptions');

    this.dialog.open(UpsertOptionsComponent, {
      disableClose: true,
      data: {mode: 'new'},
      width: '80vw',
    });
  }

  viewOptions(selectOptions: SelectOptions): void {
    this.logger.log('OptionsComponent createOptions');

    this.dialog.open(UpsertOptionsComponent, {
      disableClose: true,
      data: {mode: 'view', selectOptions: selectOptions},
      width: '80vw',
    });
  }

  editOptions(selectOptions: SelectOptions): void {
    this.logger.log('OptionsComponent createOptions');

    this.dialog.open(UpsertOptionsComponent, {
      disableClose: true,
      data: {mode: 'edit', selectOptions: selectOptions},
      width: '80vw',
    });
  }

  deleteOptions(selectOptions: SelectOptions): void {
    this.logger.log('OptionsComponent createOptions');

    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      disableClose: true,
      data: {title: 'Delete options', label: `Delete options by typing the "${ selectOptions.label }" on the field below. This process is irreversible.`, confirmString: selectOptions.label },
      width: '80vw',
    });

    dialogRef.componentInstance.delete$.subscribe((res) => {
      console.log('execute deletion');

      this.store$.dispatch(MyStore.deleteSelectOptions({id: selectOptions.id}));
      this.selectOptionsSubmit$.subscribe((elementState) => {
        if(elementState.success) dialogRef.close();
        else if(elementState.hasFailed) {
          // TODO show error message
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

}
