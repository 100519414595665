export interface WarehouseCreate {
  name: string;
  description?: string;
};

export interface WarehouseUpdate {
  name: string;
  description?: string;
  active: number;
};

export interface Warehouse {
  id: number;
  name: string;
  description?: string;
  active: number;
  archive: number;
  dateCreated: string;
  dateUpdated: string;
  createdBy: number;
  updatedBy: number;
};
