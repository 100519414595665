import { Product, CategoryBranch, NamedSchemaObj, CategoryInfo, PropertyObj } from '../../models';
import { ElementState } from './element.state';

export interface ProductState {
  products: Product[];
  selectedCategory: CategoryInfo;
  selectedCategorySchema: NamedSchemaObj | null;
  categories: CategoryBranch[];
  selectedCategoryProperties: PropertyObj[];
  schemas: NamedSchemaObj[];
  defaultSchema: NamedSchemaObj | null;
  sampleSchemas: NamedSchemaObj[];
  submit: ElementState;
}

export const initialProductState: ProductState = {
  products: [],
  selectedCategory: {
    id: '',
    name: '',
    isLeaf: false,
    level: 0,
  },
  selectedCategorySchema: null,
  categories: [],
  selectedCategoryProperties: [],
  schemas: [],
  defaultSchema: null,
  sampleSchemas: [],
  submit: new ElementState(),
};
