<div class="flex-container">

  <div class="flex-child">
    <mat-toolbar>
      <mat-toolbar-row>
        <span>Product category tree</span>
        <span class="spacer"></span>
        <button mat-icon-button aria-label="options" [matMenuTriggerFor]="productsOptions">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #productsOptions="matMenu">
          <button mat-menu-item routerLink="/schemas">Manage schemas</button>
        </mat-menu>
      </mat-toolbar-row>
    </mat-toolbar>

    <div *ngIf="dataSource.data.length > 0; else emptyCategory">
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" *ngIf="selectedCategoryState$ | async as category">
        <!-- This is the tree node template for leaf nodes -->
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
          <!-- use a disabled button to provide padding for tree leaf -->
            <button mat-icon-button disabled></button>
            <button mat-icon-button aria-label="options" [matMenuTriggerFor]="categoryLeafOptions">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #categoryLeafOptions="matMenu">
              <button mat-menu-item (click)="addCategory(node.id, 'osibling')">Add above</button>
              <button mat-menu-item (click)="addCategory(node.id, 'ysibling')">Add below</button>
              <button mat-menu-item (click)="addCategory(node.id, 'child')">Add child</button>
              <button mat-menu-item (click)="editCategory(node.id, node.name)">Edit</button>
              <button mat-menu-item (click)="deleteCategory(node.id, node.name)">Delete</button>
            </mat-menu>
            <a mat-flat-button (click)="(category.id == node.id)? unselectCategory(): selectCategory(node, true)" [color]="(category.id == node.id)? 'accent': ''">{{node.name}}</a>
        </mat-tree-node>
        <!-- This is the tree node template for expandable nodes -->
        <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
          <button mat-icon-button matTreeNodeToggle
                  [attr.aria-label]="'Toggle ' + node.name">
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          <button mat-icon-button aria-label="options" [matMenuTriggerFor]="categoryBranchOptions">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #categoryBranchOptions="matMenu">
            <button mat-menu-item (click)="addCategory(node.id, 'osibling')">Add above</button>
            <button mat-menu-item (click)="addCategory(node.id, 'ysibling')">Add below</button>
            <button mat-menu-item (click)="editCategory(node.id, node.name)">Edit</button>
          </mat-menu>
          <a mat-flat-button (click)="(category.id == node.id)? unselectCategory(): selectCategory(node, false)" [color]="(category.id == node.id)? 'accent': ''">{{node.name}}</a>
        </mat-tree-node>
      </mat-tree>
    </div>
    <ng-template #emptyCategory>
      <mat-list>
          <mat-list-item class="button-container-fix" style="text-align: center;">
            <button mat-icon-button class="no-vert-padding" (click)="addCategory(undefined, undefined)">
              <mat-icon class="mat-24">add</mat-icon>
            </button>
          </mat-list-item>
          <mat-divider></mat-divider>
      </mat-list>
    </ng-template>
  </div>

  <div class="flex-child">
    <ng-container *ngIf="selectedCategoryState$ | async as category">
      <ng-container *ngIf="category.id != ''">
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
          <mat-tab label="Properties">

            <mat-list>
              <ng-container *ngIf="categoryPropertiesState$ | async as categoryProperties">
                <mat-list-item *ngFor="let categoryProperty of categoryProperties">
                  <div matListItemIcon class="button-container-fix">
                    <button mat-icon-button class="no-vert-padding" aria-label="options" [matMenuTriggerFor]="propertyOptions">
                      <mat-icon >more_vert</mat-icon>
                    </button>
                    <mat-menu #propertyOptions="matMenu">
                      <button mat-menu-item (click)="viewCategoryProperty(categoryProperty)">View</button>
                      <button mat-menu-item (click)="editCategoryProperty(categoryProperty, 'category')">Edit</button>
                      <button mat-menu-item (click)="deleteCategoryProperty(categoryProperty)">Delete</button>
                    </mat-menu>
                  </div>
                  <span matListItemTitle>{{categoryProperty.key}}</span>
                  <span matListItemLine>{{categoryProperty.value}}</span>
                </mat-list-item>
                <mat-divider></mat-divider>
              </ng-container>

              <mat-list-item>
                <div class="align-end">
                  <button mat-icon-button class="no-vert-padding" aria-label="options" [matMenuTriggerFor]="addPropertyOptions">
                    <mat-icon >add</mat-icon>
                  </button>
                  <mat-menu #addPropertyOptions="matMenu">
                    <button mat-menu-item (click)="addCategoryProperty('text', category.id, 'category')">text</button>
                    <button mat-menu-item (click)="addCategoryProperty('long text', category.id, 'category')">long text</button>
                    <button mat-menu-item (click)="addCategoryProperty('boolean', category.id, 'category')">boolean</button>
                    <button mat-menu-item (click)="addCategoryProperty('integer', category.id, 'category')">integer</button>
                    <button mat-menu-item (click)="addCategoryProperty('number', category.id, 'category')">number</button>
                    <button mat-menu-item (click)="addCategoryProperty('date', category.id, 'category')">date</button>
                    <button mat-menu-item (click)="addCategoryProperty('time', category.id, 'category')">time</button>
                    <button mat-menu-item (click)="addCategoryProperty('date-time', category.id, 'category')">date-time</button>
                  </mat-menu>
                </div>
              </mat-list-item>

            </mat-list>

          </mat-tab>
          <mat-tab label="Inheritance">
            <mat-list>
              <ng-container *ngIf="categoryBequeathPropertiesState$ | async as categoryBequeathProperties">
                <mat-list-item *ngFor="let categoryBequeathProperty of categoryBequeathProperties">
                  <div matListItemIcon class="button-container-fix">
                    <button mat-icon-button class="no-vert-padding" aria-label="options" [matMenuTriggerFor]="propertyBequeathOptions">
                      <mat-icon >more_vert</mat-icon>
                    </button>
                    <mat-menu #propertyBequeathOptions="matMenu">
                      <button mat-menu-item (click)="viewCategoryProperty(categoryBequeathProperty)">View</button>
                      <button mat-menu-item (click)="editCategoryProperty(categoryBequeathProperty, 'bequeath')">Edit</button>
                      <button mat-menu-item (click)="deleteCategoryProperty(categoryBequeathProperty)">Delete</button>
                    </mat-menu>
                  </div>
                  <span matListItemTitle>{{categoryBequeathProperty.key}}</span>
                  <span matListItemLine>{{categoryBequeathProperty.value}}</span>
                </mat-list-item>
                <mat-divider></mat-divider>
              </ng-container>

              <mat-list-item>
                <div class="align-end">
                  <button mat-icon-button class="no-vert-padding" aria-label="options" [matMenuTriggerFor]="addPropertyBequeathOptions">
                    <mat-icon >add</mat-icon>
                  </button>
                  <mat-menu #addPropertyBequeathOptions="matMenu">
                    <button mat-menu-item (click)="addCategoryProperty('text', category.id, 'bequeath')">text</button>
                    <button mat-menu-item (click)="addCategoryProperty('long text', category.id, 'bequeath')">long text</button>
                    <button mat-menu-item (click)="addCategoryProperty('boolean', category.id, 'bequeath')">boolean</button>
                    <button mat-menu-item (click)="addCategoryProperty('integer', category.id, 'bequeath')">integer</button>
                    <button mat-menu-item (click)="addCategoryProperty('number', category.id, 'bequeath')">number</button>
                    <button mat-menu-item (click)="addCategoryProperty('date', category.id, 'bequeath')">date</button>
                    <button mat-menu-item (click)="addCategoryProperty('time', category.id, 'bequeath')">time</button>
                    <button mat-menu-item (click)="addCategoryProperty('date-time', category.id, 'bequeath')">date-time</button>
                  </mat-menu>
                </div>
              </mat-list-item>

            </mat-list>

          </mat-tab>
          <mat-tab label="Products" *ngIf="category.isLeaf">
            <mat-toolbar>
              <mat-toolbar-row>
                <span>
                  <div>{{category.name}}</div>
                  <div *ngIf="selectedCategorySchema$ | async as categorySchema" class="toolbar-subtitle">Schema: {{categorySchema.name}}</div>
                </span>
                <span class="spacer"></span>
                <button mat-icon-button aria-label="options" [matMenuTriggerFor]="categoryMenu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #categoryMenu="matMenu">
                  <button mat-menu-item (click)="createProduct(category)">Create product</button>
                  <button mat-menu-item (click)="assignCategorySchema(category)">Assign schema</button>
                </mat-menu>
              </mat-toolbar-row>
            </mat-toolbar>

            <mat-list *ngIf="productsState$ | async as products">
              <ng-container *ngFor="let product of products">
                <mat-list-item>
                  <div matListItemIcon class="button-container-fix">
                    <button mat-icon-button class="no-vert-padding" aria-label="options" [matMenuTriggerFor]="schemaOptions">
                      <mat-icon >more_vert</mat-icon>
                    </button>
                    <mat-menu #schemaOptions="matMenu">
                      <button mat-menu-item (click)="viewProduct(product)">View</button>
                      <button mat-menu-item (click)="editProduct(product)">Edit</button>
                      <button mat-menu-item (click)="deleteProduct(product)">Delete</button>
                    </mat-menu>
                  </div>
                  <span matListItemTitle>{{product.label}}</span>
                  <span matListItemLine>Active: {{product.active}}</span>
                </mat-list-item>
                <mat-divider></mat-divider>
              </ng-container>
            </mat-list>
          </mat-tab>
        </mat-tab-group>
      </ng-container>
    </ng-container>
  </div>

</div>
