export interface ProductDataObj {
  [key: string]: any;
}

export interface ProductCreateObj {
  label: string;
  data: ProductCreateObj;
}

export interface Product extends ProductCreateObj {
  id: string;
  company: string;
  category: string;
  dateCreated: Date;
  dateUpdated: Date;
  createdBy: string;
  updatedBy: string;
  active: boolean;
  archive: boolean;
}

export interface ProductOptions {
  name: string;
  options: string[];
}

export interface ProductVariation {
  name?: string;
  sku: string;
  srp: number;
  searchTags?: string[];
  variation?: ProductVariationOption[];
}

export interface ProductVariationOption {
  optionGroup: string;
  option: string;
}

export interface SaveProductSuccessResponse {
  message: string;
  data: Product;
}

export interface ArchiveProductSuccessResponse {
  message: string;
  data: string;
}

export interface Sku {
  id: string;
  company: number;
  category: string;
  brand?: string;
  name: string;
  media?: string[];

  variationName?: string;
  sku: string;
  srp: number;
  searchTags?: string;
  variationOptions?: ProductVariationOption[];

  active: boolean;
  archive?: boolean;
  dateCreated?: string;
  dateUpdated?: string;
  createdBy?: number;
  updatedBy?: number;
}

export interface ProductConfig {
  product: string;
  unit: string;
  step: number;
}

export interface ProductConfigSave {
  unit: string;
  step: number;
}

export const ProductConfigStepValues = [1, 2, 3, 4, -1, -2, -3];
export interface ProductSummary {
  total: number;
  inactive: number;
  categories: number;
  emptyCategories: number;
}
