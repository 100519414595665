import { ElementState } from './element.state';

import { DomainSummary, ProductSummary } from '../../models';

export interface DashboardState {
  productSummary?: ProductSummary;
  domainSummary?: DomainSummary;
  submit: ElementState;
}

export const initialDashboardState: DashboardState = {
  // productSummary: null,
  // domainSummary: null,
  submit: new ElementState(),
};
