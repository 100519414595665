<mat-card class="general-card">
  <mat-card-header>
    <mat-card-title-group>
      <mat-card-title>Companies</mat-card-title>
      <mat-card-subtitle>Companies you have access</mat-card-subtitle>
      <button mat-icon-button aria-label="options" [matMenuTriggerFor]="companyOptions">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #companyOptions="matMenu">
        <button mat-menu-item (click)="createNewCompanyDialog(null)">Create company</button>
      </mat-menu>
    </mat-card-title-group>
  </mat-card-header>
  <ng-container *ngIf="{selectedCompany: selectedCompany$ | async, companies: companiesState$ | async} as data">
    <mat-card-content *ngIf="data.companies as companies">
      <mat-list role="list">
        <ng-container *ngFor="let company of companies">
          <mat-list-item  class="button-container-fix">
            <div matListItemIcon class="button-container-fix">
              <button mat-icon-button class="no-vert-padding" aria-label="options" [matMenuTriggerFor]="selectCompanyOptions">
                <mat-icon >more_vert</mat-icon>
              </button>
              <mat-menu #selectCompanyOptions="matMenu">
                <button mat-menu-item (click)="viewCompanyDialog(company)">View</button>
                <button mat-menu-item (click)="editCompanyDialog(company)">Edit</button>
                <button mat-menu-item (click)="deleteCompanyDialog(company)">Delete</button>
              </mat-menu>
            </div>
            <div matListItemTitle>{{company.companyName}}</div>
          </mat-list-item>
          <mat-divider></mat-divider>
        </ng-container>
      </mat-list>
    </mat-card-content>
  </ng-container>
</mat-card>
