import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, takeWhile } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { JwtHelperService } from '@auth0/angular-jwt';

import * as MyStore from '../store';
import { environment } from '../../environments/environment';
import { Tokens } from '../models';
import { AppSettings } from '../app.settings';
import { Store, select } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  alive = true;
  accessToken: string | null = null;
  constructor(
    private router: Router,
    private http: HttpClient,
    private logger: NGXLogger,
    private store$: Store<MyStore.AppState>,
    private jwtHelper: JwtHelperService
  ) {
    this.logger.log('AuthService constructor');
    this.store$
      .pipe(select(MyStore.selectToken))
      .pipe(takeWhile(() => this.alive))
      .subscribe((tokens: Tokens) => {
        this.accessToken = tokens['access-token'];
      });
  }

  /**
   * Calls a tokensFromCode http request
   * @return {boolean}
   */
  public isValidAccessToken(): boolean {
    // if(this.accessToken == null || this.jwtHelper.isTokenExpired(this.accessToken)) return false;
    if(this.accessToken == null) return false;
    return true;
  }

  /**
   * Calls a tokensFromCode http request
   * @return {Observable<Tokens>}
   */
  getTokensFromCode(): Observable<Tokens> {
    this.logger.log('AuthService getTokensFromCode');

    const apiPath = AppSettings.pathTokensCode;
    return this.http.get<Tokens>(environment.apiUrl + apiPath);
  }

  /**
   * Calls a tokensFromRefresh http request
   * @return {Observable<Tokens>}
   */
  getTokensFromRefresh(): Observable<Tokens> {
    this.logger.log('AuthService getTokensFromRefresh');

    const apiPath = AppSettings.pathTokensRefresh;
    return this.http.get<Tokens>(environment.apiUrl + apiPath);
  }

  /**
   * Calls a revokeToken http request
   * @return {Observable<any>}
   */
  revokeToken(): Observable<any> {
    this.logger.log('AuthService revokeToken');

    const apiPath = AppSettings.pathTokensRevoke;
    return this.http.post<any>(environment.apiUrl + apiPath, null);
  }

  /**
   * After successful login
   * @param {Tokens} tokens
   */
  loginSuccess(tokens: Tokens): void {
    this.logger.log('AuthService loginSuccess');
    if (tokens) this.logger.log('has token');

    setTimeout(() => {
      this.router.navigateByUrl('/dashboard');
    }, 500);
  }
}
