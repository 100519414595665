import { createReducer, on } from '@ngrx/store';
import { getTokens, getTokensSuccess, getTokensError, logout, logoutSuccess, logoutError, refreshTokens, refreshTokensSuccess, refreshTokensError, reloadRefreshToken } from '../actions';
import { ElementState, initialAuthState } from '../states';

export const authReducer = createReducer(
  initialAuthState,
  on(getTokens, (state) => {
    return {
      ...state,
      submit: ElementState.create(true),
    };
  }),
  on(getTokensSuccess, (state, tokens) => {
    return {
      ...state,
      submit: ElementState.success('Success'),
      tokens: tokens,
    };
  }),
  on(getTokensError, (state, error) => {
    return {
      ...state,
      submit: ElementState.error(error),
    };
  }),
  on(reloadRefreshToken, (state, refreshToken) => {
    return {
      ...state,
      submit: ElementState.success('Success'),
      tokens: {
        'refresh-token': refreshToken.value,
        'access-token': null,
        'cognito-id-token': null,
        'cognito-access-token': null,
      },
    };
  }),
  on(refreshTokens, (state) => {
    return {
      ...state,
      submit: ElementState.create(true),
    };
  }),
  on(refreshTokensSuccess, (state, tokensForAccess) => {
    return {
      ...state,
      submit: ElementState.success('Success'),
      tokens: {
        ...state.tokens,
        'access-token': tokensForAccess.value['access-token'],
        'cognito-id-token': tokensForAccess.value['cognito-id-token'],
        'cognito-access-token': tokensForAccess.value['cognito-access-token'],
      },
    };
  }),
  on(refreshTokensError, (state, error) => {
    return {
      ...state,
      submit: ElementState.error(error),
      tokens: initialAuthState.tokens,
    };
  }),
  on(logout, (state) => {
    return {
      ...state,
      submit: ElementState.create(true),
    };
  }),
  on(logoutSuccess, (state) => {
    return {
      ...state,
      submit: ElementState.success('Success'),
      tokens: initialAuthState.tokens,
    };
  }),
  on(logoutError, (state, error) => {
    return {
      ...state,
      submit: ElementState.error(error),
    };
  }),
);
