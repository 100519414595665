import { createReducer, on } from '@ngrx/store';
import { addCategory, addCategoryError, addCategorySuccess, assignCategoryNamedSchema, assignCategoryNamedSchemaError, assignCategoryNamedSchemaSuccess, assignDefaultNamedSchema, assignDefaultNamedSchemaError, assignDefaultNamedSchemaSuccess, clearCategorySchema, clearProducts, createCategoryProperties, createCategoryPropertiesError, createCategoryPropertiesSuccess, createNamedSchema, createProduct, createProductError, createProductSuccess, createSchemaError, createSchemaSuccess, deleteCategory, deleteCategoryError, deleteCategoryProperty, deleteCategoryPropertyError, deleteCategoryPropertySuccess, deleteCategorySuccess, deleteProduct, deleteProductError, deleteProductSuccess, editCategory, editCategoryError, editCategorySuccess, getCategories, getCategoriesError, getCategoriesSuccess, getCategoryProperties, getCategoryPropertiesError, getCategoryPropertiesSuccess, getCategorySchema, getCategorySchemaError, getCategorySchemaSuccess, getDefaultNamedSchema, getDefaultNamedSchemaError, getDefaultNamedSchemaSuccess, getNamedSchema, getNamedSchemaError, getNamedSchemaSuccess, getNamedSchemas, getNamedSchemasError, getNamedSchemasSuccess, getProductsInCategories, getProductsInCategoriesError, getProductsInCategoriesSuccess, getSampleNamedSchemas, getSampleNamedSchemasError, getSampleNamedSchemasSuccess, resetProductState, selectCategory, unselectCategory, updateCategoryProperty, updateCategoryPropertyError, updateCategoryPropertySuccess, updateNamedSchema, updateNamedSchemaError, updateNamedSchemaSuccess, updateProduct, updateProductError, updateProductSuccess } from '../actions';
import { ElementState, initialProductState } from '../states';

export const productReducer = createReducer(
  initialProductState,
  on(resetProductState, (state) => {
    return initialProductState;
  }),
  on(getCategories, (state) => {
    return {
      ...state,
      submit: ElementState.create(true),
    };
  }),
  on(getCategoriesSuccess, (state, categories) => {
    return {
      ...state,
      submit: ElementState.success('Success'),
      categories: categories.value,
    };
  }),
  on(getCategoriesError, (state, error) => {
    return {
      ...state,
      submit: ElementState.error(error),
    };
  }),
  on(addCategory, (state) => {
    return {
      ...state,
      submit: ElementState.create(true),
    };
  }),
  on(addCategorySuccess, (state, categories) => {
    return {
      ...state,
      submit: ElementState.success('Success'),
      categories: categories.value,
    };
  }),
  on(addCategoryError, (state, error) => {
    return {
      ...state,
      submit: ElementState.error(error),
    };
  }),
  on(editCategory, (state) => {
    return {
      ...state,
      submit: ElementState.create(true),
    };
  }),
  on(editCategorySuccess, (state, categories) => {
    return {
      ...state,
      submit: ElementState.success('Success'),
      categories: categories.value,
    };
  }),
  on(editCategoryError, (state, error) => {
    return {
      ...state,
      submit: ElementState.error(error),
    };
  }),

  on(deleteCategory, (state) => {
    return {
      ...state,
      submit: ElementState.create(true),
    };
  }),
  on(deleteCategorySuccess, (state, categories) => {
    return {
      ...state,
      submit: ElementState.success('Success'),
      categories: categories.value,
    };
  }),
  on(deleteCategoryError, (state, error) => {
    return {
      ...state,
      submit: ElementState.error(error),
    };
  }),

  on(getProductsInCategories, (state, categoryId) => {
    return {
      ...state,
      submit: ElementState.create(true),
    };
  }),
  on(getProductsInCategoriesSuccess, (state, products) => {
    return {
      ...state,
      submit: ElementState.success('Success'),
      products: products.value,
    };
  }),
  on(getProductsInCategoriesError, (state, error) => {
    return {
      ...state,
      submit: ElementState.error(error),
    };
  }),
  on(selectCategory, (state, category) => {
    return {
      ...state,
      selectedCategory: category.value
    };
  }),
  on(unselectCategory, (state) => {
    return {
      ...state,
      selectedCategory: initialProductState.selectedCategory,
      selectedCategorySchema: initialProductState.selectedCategorySchema,
      selectedCategoryProperties: initialProductState.selectedCategoryProperties,
    };
  }),
  on(clearCategorySchema, (state) => {
    return {
      ...state,
      selectedCategorySchema: initialProductState.selectedCategorySchema,
    };
  }),
  on(clearProducts, (state) => {
    return {
      ...state,
      products: initialProductState.products
    };
  }),
  on(getCategorySchema, (state, categoryId) => {
    return {
      ...state,
      submit: ElementState.create(true),
    };
  }),
  on(getCategorySchemaSuccess, (state, schema) => {
    return {
      ...state,
      submit: ElementState.success('Success'),
      selectedCategorySchema: schema.value,
    };
  }),
  on(getCategorySchemaError, (state, error) => {
    return {
      ...state,
      submit: ElementState.error(error),
    };
  }),
  on(assignCategoryNamedSchema, (state) => {
    return {
      ...state,
      submit: ElementState.create(true),
    };
  }),
  on(assignCategoryNamedSchemaSuccess, (state, schema) => {
    return {
      ...state,
      submit: ElementState.success('Success'),
      selectedCategorySchema: schema.value,
    };
  }),
  on(assignCategoryNamedSchemaError, (state, error) => {
    return {
      ...state,
      submit: ElementState.error(error),
    };
  }),
  on(createNamedSchema, (state, schema) => {
    return {
      ...state,
      submit: ElementState.create(true),
    };
  }),
  on(createSchemaSuccess, (state, schema) => {
    return {
      ...state,
      submit: ElementState.success('Success'),
      schemas: [
        schema.value,
        ...state.schemas,
      ],
    };
  }),
  on(createSchemaError, (state, error) => {
    return {
      ...state,
      submit: ElementState.error(error),
    };
  }),
  on(getNamedSchema, (state, schemaId) => {
    return {
      ...state,
      submit: ElementState.create(true),
    };
  }),
  on(getNamedSchemaSuccess, (state, schema) => {
    return {
      ...state,
      submit: ElementState.success('Success'),
      schemas: [schema.value],
    };
  }),
  on(getNamedSchemaError, (state, error) => {
    return {
      ...state,
      submit: ElementState.error(error),
    };
  }),
  on(getDefaultNamedSchema, (state) => {
    return {
      ...state,
      submit: ElementState.create(true),
    };
  }),
  on(getDefaultNamedSchemaSuccess, (state, schema) => {
    return {
      ...state,
      submit: ElementState.success('Success'),
      defaultSchema: schema.value,
    };
  }),
  on(getDefaultNamedSchemaError, (state, error) => {
    return {
      ...state,
      submit: ElementState.error(error),
    };
  }),
  on(assignDefaultNamedSchema, (state) => {
    return {
      ...state,
      submit: ElementState.create(true),
    };
  }),
  on(assignDefaultNamedSchemaSuccess, (state, schema) => {
    return {
      ...state,
      submit: ElementState.success('Success'),
      defaultSchema: schema.value,
    };
  }),
  on(assignDefaultNamedSchemaError, (state, error) => {
    return {
      ...state,
      submit: ElementState.error(error),
    };
  }),
  on(getSampleNamedSchemas, (state) => {
    return {
      ...state,
      submit: ElementState.create(true),
    };
  }),
  on(getSampleNamedSchemasSuccess, (state, schemas) => {
    return {
      ...state,
      submit: ElementState.success('Success'),
      sampleSchemas: schemas.value,
    };
  }),
  on(getSampleNamedSchemasError, (state, error) => {
    return {
      ...state,
      submit: ElementState.error(error),
    };
  }),
  on(getNamedSchemas, (state, schemaId) => {
    return {
      ...state,
      submit: ElementState.create(true),
    };
  }),
  on(getNamedSchemasSuccess, (state, schemas) => {
    return {
      ...state,
      submit: ElementState.success('Success'),
      schemas: schemas.value,
    };
  }),
  on(getNamedSchemasError, (state, error) => {
    return {
      ...state,
      submit: ElementState.error(error),
    };
  }),
  on(updateNamedSchema, (state, schema) => {
    return {
      ...state,
      submit: ElementState.create(true),
    };
  }),
  on(updateNamedSchemaSuccess, (state, schemaUpdate) => {
    const updatedIndex = state.schemas.findIndex((schema) => schema.id == schemaUpdate.value.id);
    return {
      ...state,
      submit: ElementState.success('Success'),
      schemas: [
        ...state.schemas.slice(0, updatedIndex),
        schemaUpdate.value,
        ...state.schemas.slice(updatedIndex + 1),
      ],
    };
  }),
  on(updateNamedSchemaError, (state, error) => {
    return {
      ...state,
      submit: ElementState.error(error),
    };
  }),
  on(createProduct, (state) => {
    return {
      ...state,
      submit: ElementState.create(true),
    };
  }),
  on(createProductSuccess, (state, product) => {
    return {
      ...state,
      submit: ElementState.success('Success'),
      products: [
        product.value,
        ...state.products,
      ],
    };
  }),
  on(createProductError, (state, error) => {
    return {
      ...state,
      submit: ElementState.error(error),
    };
  }),
  on(updateProduct, (state) => {
    return {
      ...state,
      submit: ElementState.create(true),
    };
  }),
  on(updateProductSuccess, (state, productUpdate) => {
    const updatedIndex = state.products.findIndex((product) => product.id == productUpdate.value.id);
    return {
      ...state,
      submit: ElementState.success('Success'),
      products: [
        ...state.products.slice(0, updatedIndex),
        productUpdate.value,
        ...state.products.slice(updatedIndex + 1),
      ],
    };
  }),
  on(updateProductError, (state, error) => {
    return {
      ...state,
      submit: ElementState.error(error),
    };
  }),
  on(deleteProduct, (state) => {
    return {
      ...state,
      submit: ElementState.create(true),
    };
  }),
  on(deleteProductSuccess, (state, productDelete) => {
    const updatedIndex = state.products.findIndex((product) => product.id == productDelete.id);
    return {
      ...state,
      submit: ElementState.success('Success'),
      products: [
        ...state.products.slice(0, updatedIndex),
        ...state.products.slice(updatedIndex + 1),
      ],
    };
  }),
  on(deleteProductError, (state, error) => {
    return {
      ...state,
      submit: ElementState.error(error),
    };
  }),
  on(getCategoryProperties, (state) => {
    return {
      ...state,
      submit: ElementState.create(true),
    };
  }),
  on(getCategoryPropertiesSuccess, (state, categoryProperties) => {
    return {
      ...state,
      submit: ElementState.success('Success'),
      selectedCategoryProperties: categoryProperties.value,
    };
  }),
  on(getCategoryPropertiesError, (state, error) => {
    return {
      ...state,
      submit: ElementState.error(error),
    };
  }),
  on(createCategoryProperties, (state) => {
    return {
      ...state,
      submit: ElementState.create(true),
    };
  }),
  on(createCategoryPropertiesSuccess, (state, categoryProperty) => {
    return {
      ...state,
      submit: ElementState.success('Success'),
      selectedCategoryProperties: [
        categoryProperty.value,
        ...state.selectedCategoryProperties,
      ],
    };
  }),
  on(createCategoryPropertiesError, (state, error) => {
    return {
      ...state,
      submit: ElementState.error(error),
    };
  }),
  on(updateCategoryProperty, (state) => {
    return {
      ...state,
      submit: ElementState.create(true),
    };
  }),
  on(updateCategoryPropertySuccess, (state, propertyUpdate) => {
    const updatedIndex = state.selectedCategoryProperties.findIndex((property) => property.id == propertyUpdate.value.id);
    return {
      ...state,
      submit: ElementState.success('Success'),
      selectedCategoryProperties: [
        ...state.selectedCategoryProperties.slice(0, updatedIndex),
        propertyUpdate.value,
        ...state.selectedCategoryProperties.slice(updatedIndex + 1),
      ],
    };
  }),
  on(updateCategoryPropertyError, (state, error) => {
    return {
      ...state,
      submit: ElementState.error(error),
    };
  }),
  on(deleteCategoryProperty, (state) => {
    return {
      ...state,
      submit: ElementState.create(true),
    };
  }),
  on(deleteCategoryPropertySuccess, (state, propertyDelete) => {
    const updatedIndex = state.selectedCategoryProperties.findIndex((property) => property.id == propertyDelete.id);
    return {
      ...state,
      submit: ElementState.success('Success'),
      selectedCategoryProperties: [
        ...state.selectedCategoryProperties.slice(0, updatedIndex),
        ...state.selectedCategoryProperties.slice(updatedIndex + 1),
      ],
    };
  }),
  on(deleteCategoryPropertyError, (state, error) => {
    return {
      ...state,
      submit: ElementState.error(error),
    };
  }),
);
