import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';

import * as MyActions from '../actions';
import { SelectOptionsService } from '../../services';
import { SelectOptions, SelectOptionsCreate } from '../../models';

@Injectable()
export class SelectOptionsEffects {
  constructor(
    private actions$: Actions,
    private selectOptionsService: SelectOptionsService,
    private logger: NGXLogger,
  ) { }

  getSelectOptions$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.getSelectOptions),
    switchMap((selectOptionsFetch: { pageSize: number | null, pageOffset: number | null, includeTotal: boolean | null }) =>
      this.selectOptionsService.getSelectOptions(selectOptionsFetch.pageSize,selectOptionsFetch.pageOffset,selectOptionsFetch.includeTotal, null).pipe(
        map((selectOptions: SelectOptions[]) => MyActions.getSelectOptionsSuccess({ "value": selectOptions})),
        catchError((error) => of(MyActions.getSelectOptionsError(error))),
      ),
    ),
  ));

  createSelectOptions$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.createSelectOptions),
    switchMap((selectOptions: { value: SelectOptionsCreate }) =>
      this.selectOptionsService.createOptions(selectOptions.value).pipe(
        map((selectOptions: SelectOptions) => MyActions.createSelectOptionsSuccess({ "value": selectOptions})),
        catchError((error) => of(MyActions.createSelectOptionsError(error))),
      ),
    ),
  ));

  updateSelectOptions$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.updateSelectOptions),
    switchMap((selectOptions: { id: string, value: SelectOptionsCreate }) =>
      this.selectOptionsService.updateSelectOptions(selectOptions.id, selectOptions.value).pipe(
        map((schema: SelectOptions) => MyActions.updateSelectOptionsSuccess({ "value": schema})),
        catchError((error) => of(MyActions.updateSelectOptionsError(error))),
      ),
    ),
  ));

  deleteSelectOptions$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.deleteSelectOptions),
    switchMap((selectOptions: { id: string }) =>
      this.selectOptionsService.deleteSelectOptions(selectOptions.id).pipe(
        map(() => MyActions.deleteSelectOptionsSuccess({ id: selectOptions.id })),
        catchError((error) => of(MyActions.deleteSelectOptionsError(error))),
      ),
    ),
  ));
}
