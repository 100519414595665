import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatTreeModule } from '@angular/material/tree';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { EffectsModule } from '@ngrx/effects';

import { JsonSchemaFormModule } from '@dashjoin/json-schema-form';

import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { environment } from '../environments/environment';
import { HttpApiversionInterceptor, HttpAuthInterceptor, HttpContenttypeInterceptor, HttpSelectedcompanyInterceptor } from './interceptors';

import * as MyStore from './store';
import { LoggedinComponent } from './pages/loggedin/loggedin.component';
import { LoginComponent } from './pages/login/login.component';
import { ProductsComponent } from './pages/products/products.component';
import { SchemasComponent } from './pages/schemas/schemas.component';
import { UpsertSchemaComponent } from './pages/schemas/upsert-schema/upsert-schema.component';
import { AssignSchemaComponent } from './pages/schemas/assign-schema/assign-schema.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { UpsertCompanyComponent } from './pages/companies/upsert-company/upsert-company.component';
import { ViewCompanyComponent } from './pages/companies/view-company/view-company.component';
import { DeleteDialogComponent } from './widgets/delete-dialog/delete-dialog.component';
import { UpsertCategoryComponent } from './pages/products/categories/upsert-category/upsert-category.component';
import { UpsertProductComponent } from './pages/products/upsert-product/upsert-product.component';
import { CustomSaveDialogComponent } from './widgets/custom-save-dialog/custom-save-dialog.component';
import { SchemaBuilderComponent } from './widgets/schema-builder/schema-builder.component';
import { OptionsComponent } from './pages/options/options.component';
import { UpsertOptionsComponent } from './pages/options/upsert-options/upsert-options.component';
import { MessageDialogComponent } from './widgets/message-dialog/message-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    LoggedinComponent,
    LoginComponent,
    ProductsComponent,
    SchemasComponent,
    UpsertSchemaComponent,
    AssignSchemaComponent,
    DashboardComponent,
    UpsertCompanyComponent,
    ViewCompanyComponent,
    DeleteDialogComponent,
    UpsertCategoryComponent,
    UpsertProductComponent,
    CustomSaveDialogComponent,
    SchemaBuilderComponent,
    OptionsComponent,
    UpsertOptionsComponent,
    MessageDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatTreeModule,
    MatMenuModule,
    MatCardModule,
    JsonSchemaFormModule,
    MatDialogModule,
    MatExpansionModule,
    MatSelectModule,
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    StoreModule.forRoot(MyStore.reducers),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
    }),
    LoggerModule.forRoot({ level: !environment.production ? NgxLoggerLevel.DEBUG : NgxLoggerLevel.OFF }),
    EffectsModule.forRoot(MyStore.effects),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpApiversionInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpContenttypeInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpSelectedcompanyInterceptor,
      multi: true,
    },
    {
      provide: JWT_OPTIONS,
      useValue: JWT_OPTIONS,
    },
    JwtHelperService,
    DatePipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
