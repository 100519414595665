import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { Store, select } from '@ngrx/store';

import * as MyStore from '../../../store';
import { SelectOptions, SelectOptionsCreate, SelectOptionsDataRecords } from 'src/app/models';
import { SchemaBuilderComponent } from 'src/app/widgets/schema-builder/schema-builder.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppSettings } from 'src/app/app.settings';
import { CustomSaveDialogComponent } from 'src/app/widgets/custom-save-dialog/custom-save-dialog.component';
import { MessageDialogComponent } from 'src/app/widgets/message-dialog/message-dialog.component';
import { Observable, takeWhile } from 'rxjs';

@Component({
  selector: 'app-upsert-options',
  templateUrl: './upsert-options.component.html',
  styleUrls: ['./upsert-options.component.scss']
})
export class UpsertOptionsComponent implements OnInit, OnDestroy {
  alive = true;
  title: string;
  labelForm: FormGroup;
  optionRecords: SelectOptionsDataRecords;
  optionRecordsArray: any[];
  selectOptionsSubmitState$: Observable<MyStore.ElementState>;

  @ViewChild(SchemaBuilderComponent) child!: SchemaBuilderComponent;

  constructor(
    private logger: NGXLogger,
    private dialog: MatDialog,
    private store$: Store<MyStore.AppState>,
    private dialogRef: MatDialogRef<any>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: {mode: string, selectOptions: SelectOptions }, // new, edit, view
  ) {
    this.logger.log('UpsertProductComponent constructor');

    this.selectOptionsSubmitState$ = this.store$
      .pipe(select(MyStore.selectSelectOptionsSubmit))
      .pipe(takeWhile(() => this.alive));

    if(data.mode == 'new') {
      this.title = 'Create new select options';
    } else if(data.mode == 'edit') {
      this.title = 'Edit select options' + data.selectOptions.label;
    } else {
      this.title = 'View select options';
    }

    if(!data.selectOptions) {
      this.labelForm = this.fb.group ({
        label: ['', [Validators.required, Validators.pattern(AppSettings.regexComplete)]],
      });

      this.optionRecords = {};
    } else {
      this.labelForm = this.fb.group ({
        label: [data.selectOptions.label, [Validators.required, Validators.pattern(AppSettings.regexComplete)]],
      });

      this.optionRecords = data.selectOptions.data;

      setTimeout(() => {
        this.child.import(data.selectOptions.schema);
      }, 500);
    }
    this.optionRecordsArray = Object.entries(this.optionRecords);
  }

  ngOnInit(): void {
  }

  createOption(): void {
    this.logger.log('UpsertOptionsComponent createOption');

    if(!this.child.isValid()) {
      this.dialog.open(MessageDialogComponent, {
        disableClose: true,
        data: {title: 'Invalid schema', content: "You can't create an option with an invalid schema.", closeLabel: 'Close'},
      });
      return;
    }

    const schema: any = {
      "type": "object",
      "layout": "vertical",
      "required": [
        "key",
        "value",
      ],
      "properties": {
        "key": {
          "type": "string",
        },
        "value": this.child.export(),
      },
    }
    const dialogRef = this.dialog.open(CustomSaveDialogComponent, {
      disableClose: true,
      data: {title: 'Add option', schema: schema},
      width: '70vw',
    });

    dialogRef.componentInstance.save$.subscribe((res) => {
      console.log('save option',res);

      this.optionRecords[res.key] = res.value;
      this.optionRecordsArray = Object.entries(this.optionRecords);
      this.logger.log('optionRecords', this.optionRecords);
      dialogRef.close();
    });
  }

  editOption(key: string): void {
    this.logger.log('UpsertOptionsComponent editOption', key);

    if(!this.child.isValid()) {
      this.dialog.open(MessageDialogComponent, {
        disableClose: true,
        data: {title: 'Invalid schema', content: "You can't create an option with an invalid schema.", closeLabel: 'Close'},
      });
      return;
    }

    const data: any = {
      key: key,
      value: this.optionRecords[key],
    };

    const schema: any = {
      "type": "object",
      "layout": "vertical",
      "required": [
        "key",
        "value",
      ],
      "properties": {
        "key": {
          "type": "string",
          "readOnly": true,
        },
        "value": this.child.export(),
      },
    }
    const dialogRef = this.dialog.open(CustomSaveDialogComponent, {
      disableClose: true,
      data: {title: 'Edit option', schema: schema, data: data, mode: 'edit'},
      width: '70vw',
    });

    dialogRef.componentInstance.save$.subscribe((res) => {
      console.log('save option',res);

      const temp = JSON.parse(JSON.stringify(this.optionRecords));
      temp[res.key] = res.value;
      this.optionRecords = temp;
      this.optionRecordsArray = Object.entries(this.optionRecords);
      this.logger.log('optionRecords', this.optionRecords);
      dialogRef.close();
    });
  }

  viewOption(key: string): void {
    this.logger.log('UpsertOptionsComponent viewOption', key);

    if(!this.child.isValid()) {
      this.dialog.open(MessageDialogComponent, {
        disableClose: true,
        data: {title: 'Invalid schema', content: "You can't create an option with an invalid schema.", closeLabel: 'Close'},
      });
      return;
    }

    const data: any = {
      key: key,
      value: this.optionRecords[key],
    };

    const schema: any = {
      "type": "object",
      "layout": "vertical",
      "required": [
        "key",
        "value",
      ],
      "properties": {
        "key": {
          "type": "string",
          "readOnly": true,
        },
        "value": this.child.export(),
      },
    }
    this.dialog.open(CustomSaveDialogComponent, {
      disableClose: true,
      data: {title: 'Edit option', schema: schema, data: data, mode: 'view'},
      width: '70vw',
    });
  }

  deleteOption(key: string): void {
    this.logger.log('UpsertOptionsComponent deleteOption', key);

    const temp = JSON.parse(JSON.stringify(this.optionRecords));
    delete temp[key];
    this.optionRecords = temp;
    this.optionRecordsArray = Object.entries(this.optionRecords);
    this.logger.log('optionRecords', this.optionRecords);
  }

  save(): void {
    this.logger.log('UpsertOptionsComponent save');

    if(!this.child.isValid()) {
      this.dialog.open(MessageDialogComponent, {
        disableClose: true,
        data: {title: 'Invalid schema', content: "Schema is invalid.", closeLabel: 'Close'},
      });
      return;
    }

    if(Object.keys(this.optionRecords).length == 0) {
      this.dialog.open(MessageDialogComponent, {
        disableClose: true,
        data: {title: 'No options', content: "You need at least 1 option.", closeLabel: 'Close'},
      });
      return;
    }

    const selectOptionsCreate: SelectOptionsCreate = {
      label: this.labelForm.value.label,
      schema: this.child.export(),
      data: this.optionRecords,
    }
    this.logger.log('selectOptionsCreate', selectOptionsCreate);

    if(this.data.mode == 'new') this.store$.dispatch(MyStore.createSelectOptions({value: selectOptionsCreate}));
    else this.store$.dispatch(MyStore.updateSelectOptions({id: this.data.selectOptions.id, value: selectOptionsCreate}));

    this.selectOptionsSubmitState$.subscribe((elementState) => {
      if(elementState.success) this.dialogRef.close();
    });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

}
