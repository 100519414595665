import { ElementState, SelectOptionsState } from '../states';
import { SelectOptions } from '../../models';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

const getSelectOptions = (state: SelectOptionsState) => state.selectOptions;
const getSelectOptionsSubmit = (state: SelectOptionsState) => state.submit;

export const selectSelectOptionsState: MemoizedSelector<object, SelectOptionsState> = createFeatureSelector<SelectOptionsState>('selectOptions');

export const selectSelectOptions: MemoizedSelector<object, SelectOptions[]> = createSelector(
  selectSelectOptionsState,
  getSelectOptions,
);

export const selectSelectOptionsSubmit: MemoizedSelector<object, ElementState> = createSelector(
  selectSelectOptionsState,
  getSelectOptionsSubmit,
);
