import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';

import { State, Schema } from '@dashjoin/json-schema-form';

import { CategoryBranch, Product, NamedSchemaObj, NamedSchemaCreateObj, NamedSchemaIdObj, CategoryUpsert, ProductCreateObj, PropertyCreateObj, PropertyObj } from '../models';
import { environment } from '../../environments/environment';
import { AppSettings } from '../app.settings';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
  ) {
    this.logger.log('ProductService constructor');
  }

  /**
   * Calls a getCategories http request
   * @return {Observable<CategoryBranch[]>}
   */
  getCategories(): Observable<CategoryBranch[]> {
    this.logger.log('ProductService getCategories');
    const apiPath = AppSettings.pathProductsCategories;
    return this.http.get<CategoryBranch[]>(environment.apiUrl + apiPath);
  }

  /**
   * Calls a addCategory http request
   * @return {Observable<CategoryBranch[]>}
   */
  addCategory(categoryUpsert: CategoryUpsert): Observable<CategoryBranch[]> {
    this.logger.log('ProductService addCategory');
    const apiPath = AppSettings.pathProductsCategories;
    return this.http.post<CategoryBranch[]>(environment.apiUrl + apiPath, categoryUpsert);
  }

  /**
   * Calls a editCategory http request
   * @return {Observable<CategoryBranch[]>}
   */
  editCategory(categoryId: string, categoryUpsert: CategoryUpsert): Observable<CategoryBranch[]> {
    this.logger.log('ProductService editCategory');
    const apiPath = AppSettings.pathProductsCategories + '/id/' + categoryId;
    return this.http.patch<CategoryBranch[]>(environment.apiUrl + apiPath, categoryUpsert);
  }

  /**
   * Calls a deleteCategory http request
   * @return {Observable<CategoryBranch[]>}
   */
  deleteCategory(categoryId: string): Observable<CategoryBranch[]> {
    this.logger.log('ProductService deleteCategory');
    const apiPath = AppSettings.pathProductsCategories + '/id/' + categoryId;
    return this.http.delete<CategoryBranch[]>(environment.apiUrl + apiPath);
  }

  /**
   * Calls a getCategories http request
   * @return {Observable<CategoryBranch[]>}
   */
  getProductsInCategory(categoryId: string): Observable<Product[]> {
    this.logger.log('ProductService getProductsInCategory');
    const apiPath = '/products/categories/id/' + categoryId + '/products';
    return this.http.get<Product[]>(environment.apiUrl + apiPath);
  }

  /**
   * Calls a createSchema http request
   * @return {Observable<NamedSchemaObj>}
   */
  createSchema(namedSchema: NamedSchemaCreateObj): Observable<NamedSchemaObj> {
    this.logger.log('ProductService createSchema');
    const apiPath = AppSettings.pathProductsSchemas;
    return this.http.post<NamedSchemaObj>(environment.apiUrl + apiPath, namedSchema);
  }

  /**
   * Calls a getSchema http request
   * @return {Observable<NamedSchemaObj>}
   */
  getSchema(schemaId: string): Observable<NamedSchemaObj> {
    this.logger.log('ProductService getSchema');
    const apiPath = AppSettings.pathProductsSchemas + '/id/' + schemaId;
    return this.http.get<NamedSchemaObj>(environment.apiUrl + apiPath);
  }

  /**
   * Calls a getSchemaOfCategory http request
   * @return {Observable<NamedSchemaObj[]>}
   */
  getSchemas(pageSize: number | null, pageOffset: number | null, includeTotal: boolean | null, search: string | null): Observable<NamedSchemaObj[]> {
    this.logger.log('ProductService getSchemas');
    const apiPath = AppSettings.pathProductsSchemas;
    if(pageSize != null || pageOffset != null || includeTotal != null  || search != null) {
      let params = new HttpParams();
      if(pageSize) params = params.append('pageSize', pageSize);
      if(pageOffset) params = params.append('pageOffset', pageOffset);
      if(includeTotal) params = params.append('includeTotal', includeTotal);
      if(search) params = params.append('s', search);
      return this.http.get<NamedSchemaObj[]>(environment.apiUrl + apiPath, { params: params });
    }
    return this.http.get<NamedSchemaObj[]>(environment.apiUrl + apiPath);
  }

  /**
   * Calls a getDefaultSchema http request
   * @return {Observable<NamedSchemaObj>}
   */
  getDefaultSchema(): Observable<NamedSchemaObj> {
    this.logger.log('ProductService getDefaultSchema');
    const apiPath = AppSettings.pathProductsSchemasDefault;
    return this.http.get<NamedSchemaObj>(environment.apiUrl + apiPath);
  }

  /**
   * Calls a getSampleSchemas http request
   * @return {Observable<NamedSchemaObj[]>}
   */
  getSampleSchemas(): Observable<NamedSchemaObj[]> {
    this.logger.log('ProductService getDefaultSchema');
    const apiPath = AppSettings.pathProductsSchemasSamples;
    return this.http.get<NamedSchemaObj[]>(environment.apiUrl + apiPath);
  }

  /**
   * Calls a createSchema http request
   * @return {Observable<NamedSchemaObj>}
   */
  updateSchema(schemaId: string, namedSchema: NamedSchemaCreateObj): Observable<NamedSchemaObj> {
    this.logger.log('ProductService createSchema');
    const apiPath = AppSettings.pathProductsSchemas + '/id/' + schemaId;
    return this.http.put<NamedSchemaObj>(environment.apiUrl + apiPath, namedSchema);
  }

  /**
   * Calls a assignDefaultSchema http request
   * @return {Observable<NamedSchemaObj>}
   */
  assignDefaultSchema(namedSchemaId: NamedSchemaIdObj): Observable<NamedSchemaObj> {
    this.logger.log('ProductService assignDefaultSchema');
    const apiPath = AppSettings.pathProductsSchemasDefault;
    return this.http.put<NamedSchemaObj>(environment.apiUrl + apiPath, namedSchemaId);
  }

  /**
   * Calls a getSchemaOfCategory http request
   * @return {Observable<NamedSchemaObj>}
   */
  getSchemaOfCategory(categoryId: string): Observable<NamedSchemaObj> {
    this.logger.log('ProductService getSchemaOfCategory');
    // const apiPath = '/products/categories/id/' + categoryId + '/schema';
    const apiPath = AppSettings.pathProductsSchemasCategories + categoryId;
    return this.http.get<NamedSchemaObj>(environment.apiUrl + apiPath);
  }

  /**
   * Calls a assignCategorySchema http request
   * @return {Observable<NamedSchemaObj>}
   */
  assignCategorySchema(namedSchemaId: NamedSchemaIdObj, categoryId: string): Observable<NamedSchemaObj> {
    this.logger.log('ProductService assignCategorySchema');
    const apiPath = AppSettings.pathProductsSchemasCategories + categoryId;
    return this.http.put<NamedSchemaObj>(environment.apiUrl + apiPath, namedSchemaId);
  }

  /**
   * Calls a createProduct http request
   * @return {Observable<Product>}
   */
  createProduct(product: ProductCreateObj, categoryId: string): Observable<Product> {
    this.logger.log('ProductService createProduct');
    const apiPath = AppSettings.pathProductsCategories + '/id/' + categoryId + '/products';
    return this.http.post<Product>(environment.apiUrl + apiPath, product);
  }

  /**
   * Calls a putProduct http request
   * @return {Observable<Product>}
   */
  updateProduct(product: ProductCreateObj, productId: string): Observable<Product> {
    this.logger.log('ProductService putProduct');
    const apiPath = AppSettings.pathProducts + '/id/' + productId;
    return this.http.put<Product>(environment.apiUrl + apiPath, product);
  }

  /**
   * Calls a deleteProduct http request
   * @return {Observable<any>}
   */
  deleteProduct(productId: string): Observable<any> {
    this.logger.log('ProductService deleteProduct');
    const apiPath = AppSettings.pathProducts + '/id/' + productId;
    return this.http.delete<any>(environment.apiUrl + apiPath);
  }

  /**
   * Calls a createProduct http request
   * @return {Observable<PropertyObj>}
   */
  createProperty(property: PropertyCreateObj, categoryId: string): Observable<PropertyObj> {
    this.logger.log('ProductService createProperty');
    const apiPath = AppSettings.pathProductsPropertiesCategories + categoryId;
    return this.http.post<PropertyObj>(environment.apiUrl + apiPath, property);
  }

  /**
   * Calls a getCategoryProperties http request
   * @return {Observable<PropertyObj[]>}
   */
  getCategoryProperties(categoryId: string): Observable<PropertyObj[]> {
    this.logger.log('ProductService getCategoryProperties');
    const apiPath = AppSettings.pathProductsPropertiesCategories + categoryId;
    return this.http.get<PropertyObj[]>(environment.apiUrl + apiPath);
  }

  /**
   * Calls a updateCategoryProperty http request
   * @return {Observable<PropertyObj>}
   */
  updateCategoryProperty(property: PropertyCreateObj, propertyId: string): Observable<PropertyObj> {
    this.logger.log('ProductService updateCategoryProperty');
    const apiPath = AppSettings.pathProductsPropertiesId + propertyId;
    return this.http.put<PropertyObj>(environment.apiUrl + apiPath, property);
  }

  /**
   * Calls a updateCategoryProperty http request
   * @return {Observable<any>}
   */
  deleteCategoryProperty(propertyId: string): Observable<any> {
    this.logger.log('ProductService deleteCategoryProperty');
    const apiPath = AppSettings.pathProductsPropertiesId + propertyId;
    return this.http.delete<any>(environment.apiUrl + apiPath);
  }
}
