import { AuthEffects } from './auth.effects';
import { CompanyEffects } from './company.effects';
import { ProductEffects } from './product.effects';
import { SelectOptionsEffects } from './select-options.effects';
import { UserEffects } from './user.effects';

export * from './auth.effects';
export * from './company.effects';
export * from './user.effects';

export const effects = [
  AuthEffects,
  CompanyEffects,
  ProductEffects,
  SelectOptionsEffects,
  UserEffects,
];
