import { Schema } from '@dashjoin/json-schema-form';
import { v4 as uuidv4 } from 'uuid';

export interface NamedSchemaCreateObj {
  name: string;
  schema: Schema;
}

export interface NamedSchemaObj extends NamedSchemaCreateObj {
  id: string;
  dateCreated: Date;
  dateUpdated: Date;
  createdBy: string;
  updatedBy: string;
  active: boolean;
  archive: boolean;
}

export interface NamedSchemaIdObj {
  id: string;
}

export interface SchemaNodeLeaf {
  id: string;
  name: string;
  type: string;
  required: boolean;
  widget?: string;

  // layout?: string;
  // widgetType?: string;
  // choicesUrl?: string;
  // choicesVerb?: string;
  // jsonata?: string;
  // choices?: string[];
  // dateFormat?: string;
  // enum?: string[];
  // readOnly?: boolean;
  // pattern?: string;
  // format?: string;
  // multipleOf?: number;
  // minimum?: number;
  // maximum?: number;
  // minItems?: number;
  // maxItems?: number;
  // uniqueItems?: boolean;
  // errorMessage?: string;
  // switch?: string;
  // case?: string[];
  // items?: SchemaNodeLeaf;
};

export interface SchemaNode extends SchemaNodeLeaf {
  properties?: SchemaNode[];
};

export const convertSchemaNodeToSchema = (schemaNodeTree: SchemaNode[], parentType: string): Schema => {
  let schemaVal: any = {};
  for (let node of schemaNodeTree) {
    const temp:any = {};
    temp.type = node.type;
    if(node.widget) temp.widget = node.widget;

    if(node.properties) {
      const props = convertSchemaNodeToSchema(node.properties, node.type);
      temp.layout = 'vertical';
      if(node.type == 'object') {
        const required: string[] = [];
        for (let prop of node.properties) {
          if(prop.required) required.push(prop.name);
        }
        if(required.length > 0) temp.required = required;

        temp.properties = props;
      }
      else if(node.type == 'array') temp.items = props;
    }

    if(parentType == 'object') schemaVal[node.name] = temp;
    else schemaVal = temp;
  }

  return schemaVal;
}

export const convertSchemaToSchemaNode = (schema: Schema, parentType: any, required: string[] | undefined): SchemaNode[] => {
  let schemaNodeVal: SchemaNode[] = [];

  if(parentType != 'object') {
    const temp:any = {
      type: schema.type,
      id: uuidv4(),
      widget: (schema.widget)? schema.widget : undefined,
    };

    if(schema.properties) {
      temp.properties = convertSchemaToSchemaNode(schema.properties, schema.type, schema.required);
    }
    if(schema.items) {
      temp.properties = convertSchemaToSchemaNode(schema.items, schema.type, undefined);
    }

    schemaNodeVal.push(temp);
  }
  else if(parentType == 'object') {
    const entries = Object.entries(schema);
    for (let entry of entries) {
      const temp:any = {
        name: entry[0],
        type: entry[1].type,
        id: uuidv4(),
        widget: (entry[1].widget)? entry[1].widget : undefined,
        required: (required !== undefined && required.length > 0)? required.includes(entry[0]) : undefined,
      };

      if(entry[1].properties) {
        temp.properties = convertSchemaToSchemaNode(entry[1].properties, entry[1].type, schema.required);
      }
      if(entry[1].items) {
        temp.properties = convertSchemaToSchemaNode(entry[1].items, entry[1].type, undefined);
      }

      schemaNodeVal.push(temp);
    }
  }

  return schemaNodeVal;
}
