import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { FlatTreeControl } from '@angular/cdk/tree';
import { v4 as uuidv4 } from 'uuid';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Schema } from '@dashjoin/json-schema-form';
import { SchemaNode } from 'src/app/models';
import { CustomSaveDialogComponent } from '../custom-save-dialog/custom-save-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TreeService } from 'src/app/services/tree.service';
import { convertSchemaNodeToSchema, convertSchemaToSchemaNode } from '../../models';

export interface TreeFlatNode {
  expandable: boolean;
  id: string;
  name: string;
  level: number;
  type: string;
  ref: SchemaNode;
}

@Component({
  selector: 'app-schema-builder',
  templateUrl: './schema-builder.component.html',
  styleUrls: ['./schema-builder.component.scss']
})
export class SchemaBuilderComponent implements OnInit, OnDestroy {
  alive = true;
  @Input() viewOnly!: boolean;

  _transformer = (node: SchemaNode, level: number) => {
    return {
      expandable: !!node.properties && node.properties.length > 0,
      id: node.id,
      name: node.name,
      level: level,
      type: node.type,
      ref: node,
    };
  };
  treeControl = new FlatTreeControl<TreeFlatNode>(
    node => node.level,
    node => node.expandable,
  );
  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.properties,
  );
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  hasChild = (_: number, node: TreeFlatNode) => node.expandable;
  treeData: SchemaNode[];

  constructor(
    private logger: NGXLogger,
    private dialog: MatDialog,
    private treeService: TreeService,
  ) {
    this.logger.log('SchemaBuilderComponent constructor');

    this.treeData = [];
  }

  ngOnInit(): void {
    this.logger.log('SchemaBuilderComponent ngOnInit', this.viewOnly);

    this.dataSource.data = JSON.parse(JSON.stringify(this.treeData));
    this.treeControl.expandAll();
  }

  schemaForm: any = {
    "type": "object",
    "layout": "vertical",
    "switch": "type",
    "required": [
      "name",
      "type",
    ],
    "properties": {
      "name": {
        "type": "string",
        "title": "Name",
      },
      "type": {
        "type": "string",
        "title": "Type",
        "enum": [
          "object",
          "array",
          "string",
          "number",
          "integer",
          "boolean",
        ]
      },
      "widget": {
        "type": "string",
        "title": "Widget",
        "enum": [
          "textarea",
          "password",
          "date",
          "datetime-local",
          "time",
        ],
        "case": [
          "string"
        ],
      },
      "required": {
        "type": "boolean",
        "title": "Required"
      },
    }
  };

  schemaFormNoName = {
    ...this.schemaForm,
    required: [
      "type",
    ],
    properties: {
      ...this.schemaForm.properties,
      name: undefined,
    }
  }

  schemaFormEdit: any = {
    "type": "object",
    "layout": "vertical",
    "switch": "type",
    "required": [
      "name",
      "type",
    ],
    "properties": {
      "name": {
        "type": "string",
        "title": "Name",
      },
      "type": {
        "type": "string",
        "title": "Type",
        "readOnly": true,
        "enum": [
          "object",
          "array",
          "string",
          "number",
          "integer",
          "boolean",
        ]
      },
      "widget": {
        "type": "string",
        "title": "Widget",
        "enum": [
          "textarea",
          "password",
          "date",
          "datetime-local",
          "time",
        ],
        "case": [
          "string"
        ],
      },
      "required": {
        "type": "boolean",
        "title": "Required"
      },
    }
  };

  schemaFormEditNoName = {
    ...this.schemaFormEdit,
    required: [
      "type",
    ],
    properties: {
      ...this.schemaFormEdit.properties,
      name: undefined,
    }
  }

  add(): void {
    this.logger.log('SchemaBuilderComponent add');

    // data: {title: string, schema: Schema, data: any, mode: string}, // new, edit, view

    const dialogRef = this.dialog.open(CustomSaveDialogComponent, {
      disableClose: true,
      data: {title: 'Add node', schema: this.schemaFormNoName, mode: 'new'},
      width: '80vw',
    });

    dialogRef.componentInstance.save$.subscribe((res) => {
      console.log('save property',res);

      const temp = {
        ...res,
        id: uuidv4(),
      }

      this.treeData.push(
        temp,
      );

      this.dataSource.data = JSON.parse(JSON.stringify(this.treeData));
      this.treeControl.expandAll();
      dialogRef.close();
    });
  }

  edit(ref: SchemaNode): void {
    this.logger.log('SchemaBuilderComponent edit');

    // data: {title: string, schema: Schema, data: any, mode: string}, // new, edit, view

    const dialogRef = this.dialog.open(CustomSaveDialogComponent, {
      disableClose: true,
      data: {title: 'Edit node', schema: (ref.name)? this.schemaFormEdit: this.schemaFormEditNoName, data: ref, mode: 'edit'},
      width: '80vw',
    });

    dialogRef.componentInstance.save$.subscribe((res) => {
      console.log('save property',res);

      const node = this.treeService.getObject(ref.id, this.treeData, 'properties');
      this.logger.log('SchemaBuilderComponent edit node', node);

      node.name = res.name;
      node.required = res.required;
      if(node.type == 'string') node.widget = res.widget;
      else node.widget = undefined;

      this.dataSource.data = JSON.parse(JSON.stringify(this.treeData));
      this.treeControl.expandAll();
      dialogRef.close();
    });
  }

  editFull(ref: SchemaNode): void {
    this.logger.log('SchemaBuilderComponent editFull');

    const dialogRef = this.dialog.open(CustomSaveDialogComponent, {
      disableClose: true,
      data: {title: 'Edit node', schema: (ref.name)? this.schemaFormEdit: this.schemaFormEditNoName, data: ref, mode: 'edit'},
      width: '80vw',
    });

    dialogRef.componentInstance.save$.subscribe((res) => {
      console.log('save property',res);

      const node = this.treeService.getObject(ref.id, this.treeData, 'properties');
      this.logger.log('SchemaBuilderComponent edit node', node);

      node.name = res.name;
      node.type = res.type;
      node.required = res.required;
      if(node.type == 'string') node.widget = res.widget;
      else node.widget = undefined;
      this.logger.log('SchemaBuilderComponent edited node', node);

      this.dataSource.data = JSON.parse(JSON.stringify(this.treeData));
      this.treeControl.expandAll();
      dialogRef.close();
    });
  }

  addProperty(ref: SchemaNode): void {
    this.logger.log('SchemaBuilderComponent add');

    const dialogRef = this.dialog.open(CustomSaveDialogComponent, {
      disableClose: true,
      data: {title: 'Add node', schema: (ref.type == 'object')? this.schemaForm : this.schemaFormNoName, mode: 'new'},
      width: '80vw',
    });

    dialogRef.componentInstance.save$.subscribe((res) => {
      console.log('save property',res);

      const node = this.treeService.getObject(ref.id, this.treeData, 'properties');
      this.logger.log('SchemaBuilderComponent addProperty node', node);
      const childNode = {
        ...res,
        id: uuidv4(),
      }
      if(node.hasOwnProperty('properties')) {
        node.properties.push(childNode);
      } else {
        node.properties = [childNode];
      }
      this.logger.log('SchemaBuilderComponent addProperty node', node);
      this.logger.log('SchemaBuilderComponent addProperty treeData', this.treeData);

      this.dataSource.data = JSON.parse(JSON.stringify(this.treeData));
      this.treeControl.expandAll();
      dialogRef.close();
    });
  }

  deleteProperty(ref: SchemaNode): void {
    this.logger.log('SchemaBuilderComponent deleteProperty');
    this.treeService.deleteObject(ref.id, this.treeData, 'properties');

    this.dataSource.data = JSON.parse(JSON.stringify(this.treeData));
    this.treeControl.expandAll();
  }

  isValid(): boolean {
    if(this.treeData.length == 0) return false;

    const leaves = this.treeService.getLeaves(this.treeData, 'properties');
    for (let leaf of leaves) {
      if(leaf.type == 'object' || leaf.type == 'array') return false;
    }
    return true;
  }

  import(schema: Schema): void {
    this.logger.log('SchemaBuilderComponent import', schema);

    this.treeData = convertSchemaToSchemaNode(schema, '', undefined);
    this.logger.log('SchemaBuilderComponent import', JSON.stringify(this.treeData));
    this.dataSource.data = this.treeData;
    this.treeControl.expandAll();
  }

  export(): Schema {
    this.logger.log('SchemaBuilderComponent export', JSON.stringify(this.treeData));
    return convertSchemaNodeToSchema(this.treeData, '');
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

}
