<h2 mat-dialog-title>
  {{title}}
</h2>
<mat-dialog-content class="mat-typography">
  <ng-container *ngIf="labelForm">
    <form [formGroup]="labelForm" (ngSubmit)="$event.preventDefault()">
      <mat-form-field>
        <mat-label>Label</mat-label>
        <input matInput cdkFocusInitial formControlName="label" [readonly]="data.mode == 'view'">
      </mat-form-field>
    </form>
  </ng-container>

  <mat-accordion class="example-headers-align" multi>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Schema
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-schema-builder [viewOnly]="data.mode=='view'"></app-schema-builder>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-card class="general-card">
    <mat-card-header>
      <mat-card-title-group>
        <mat-card-title>Options</mat-card-title>
        <button *ngIf="data.mode!='view'" mat-icon-button aria-label="options" [matMenuTriggerFor]="selectOptions">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #selectOptions="matMenu">
          <button mat-menu-item (click)="createOption()">Create options</button>
        </mat-menu>
      </mat-card-title-group>
    </mat-card-header>
    <ng-container *ngIf="this.optionRecords as optionRecords">
      <mat-card-content>
        <mat-list role="list">
          <ng-container *ngFor="let record of optionRecordsArray">
            <mat-list-item >
              <div matListItemIcon class="button-container-fix">
                <button mat-icon-button class="no-vert-padding" aria-label="options" [matMenuTriggerFor]="recordOptions">
                  <mat-icon >more_vert</mat-icon>
                </button>
                <mat-menu #recordOptions="matMenu">
                  <button mat-menu-item (click)="viewOption(record[0])">View</button>
                  <button mat-menu-item *ngIf="data.mode!='view'" (click)="editOption(record[0])">Edit</button>
                  <button mat-menu-item *ngIf="data.mode!='view'" (click)="deleteOption(record[0])">Delete</button>
                </mat-menu>
              </div>
              <div matListItemTitle>{{record[0]}}</div>
            </mat-list-item>
            <mat-divider></mat-divider>
          </ng-container>
        </mat-list>
      </mat-card-content>
    </ng-container>
  </mat-card>


</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close *ngIf="data.mode == 'view'">Close</button>
  <!-- <button mat-button *ngIf="(data.mode == 'view')" (click)="duplicate()">Duplicate</button> -->
  <ng-container *ngIf="data.mode != 'view'">
    <button mat-button mat-dialog-close *ngIf="data.mode != 'view'">Cancel</button>
    <button mat-button *ngIf="data.mode != 'view'" [disabled]="!labelForm.valid" (click)="save()">Save</button>
  </ng-container>
</mat-dialog-actions>
