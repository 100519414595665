import { Component, OnDestroy, OnInit, Inject, ViewChild } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Store, select } from '@ngrx/store';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, takeWhile } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Schema } from '@dashjoin/json-schema-form';

import * as MyStore from '../../../store';
import { NamedSchemaCreateObj, NamedSchemaObj } from '../../../models';
import { SchemaBuilderComponent } from 'src/app/widgets/schema-builder/schema-builder.component';

@Component({
  selector: 'app-upsert-schema',
  templateUrl: './upsert-schema.component.html',
  styleUrls: ['./upsert-schema.component.scss']
})
export class UpsertSchemaComponent implements OnInit, OnDestroy {
  alive = true;
  title: string;
  schemaForm: FormGroup;
  productSubmitState$: Observable<MyStore.ElementState>;
  holdValidation: boolean;

  @ViewChild(SchemaBuilderComponent) child!: SchemaBuilderComponent;

  constructor(
    private logger: NGXLogger,
    private store$: Store<MyStore.AppState>,
    private dialogRef: MatDialogRef<any>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: {mode: string, namedSchema: NamedSchemaObj}, // new, edit, view
  ) {
    this.logger.log('UpsertSchemaComponent constructor', data);

    if(data.mode == 'new') {
      this.title = 'New schema';
    } else if(data.mode == 'edit') {
      this.title = 'Edit schema';
    } else {
      this.title = 'View schema';
    }

    if(!data.namedSchema) {
      this.schemaForm = this.fb.group ({
        name: ['', [Validators.required]],
        // schema: ['', [Validators.required]],
      });
      this.holdValidation = false;
    } else {
      this.holdValidation = true;
      this.schemaForm = this.fb.group ({
        name: [data.namedSchema.name, [Validators.required]],
        // schema: [JSON.stringify(data.namedSchema.schema, null, 2), [Validators.required]],
      });
      setTimeout(() => {
        this.child.import(data.namedSchema.schema);
        this.holdValidation = false;
      }, 500);
    }

    this.productSubmitState$ = this.store$
      .pipe(select(MyStore.selectProductSubmit))
      .pipe(takeWhile(() => this.alive));
  }

  ngOnInit() {
    this.logger.log('UpsertSchemaComponent ngOnInit');
  }

  handleKeydown(event:any) {
    if (event.key == 'Tab') {
        event.preventDefault();
        const start = event.target.selectionStart;
        const end = event.target.selectionEnd;
        event.target.value = event.target.value.substring(0, start) + '\t' + event.target.value.substring(end);
        event.target.selectionStart = event.target.selectionEnd = start + 1;
    }
  }

  beautify() {
    this.schemaForm['controls']['schema'].setValue(JSON.stringify(JSON.parse(this.schemaForm['controls']['schema'].value), null, 2));
  }

  duplicate() {
    const schemaObj = this.child.export();
    const schema: NamedSchemaCreateObj = {
      name: this.schemaForm.value.name,
      schema: schemaObj,
    }
    this.dialogRef.close(schema);
  }

  isValidSchema(): boolean {
    if(this.holdValidation) return false;
    return this.schemaForm.valid && this.child.isValid();
  }

  save() {
    this.logger.log('UpsertSchemaComponent save', this.schemaForm.value);
    if(!this.isValidSchema()) return;

    const schemaObj = this.child.export();
    const schema: NamedSchemaCreateObj = {
      name: this.schemaForm.value.name,
      schema: schemaObj,
    }

    if(this.data.mode == 'new') this.store$.dispatch(MyStore.createNamedSchema({value: schema}));
    else this.store$.dispatch(MyStore.updateNamedSchema({id: this.data.namedSchema.id, value: schema}));

    this.productSubmitState$.subscribe((elementState) => {
      if(elementState.success) this.dialogRef.close();
    });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

}
