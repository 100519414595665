import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';

// import { UserActionTypes, GetUserError, GetUserSuccess } from '../actions';
import * as MyActions from '../actions';
import { UserService } from '../../services';
import { User } from '../../models';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private userService: UserService,
    private logger: NGXLogger,
  ) { }

  getUser$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.getUser),
    switchMap(() =>
      this.userService.getUser().pipe(
        map((user: User) => MyActions.getUserSuccess(user)),
        catchError((error) => of(MyActions.getUserError(error))),
      ),
    ),
  ));

  getUserSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.getUserSuccess),
    map((user: User) => {
      this.userService.getUserSuccess(user);
    }),
  ), { dispatch: false });

  removeUser$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.removeUser),
    map(() => {
      this.logger.log('removeUser$ effect');
    }),
  ), { dispatch: false });
}
