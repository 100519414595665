import { DnsRecord, Domain, NameServer } from '../../models';
import { ElementState } from './element.state';

export interface DomainState {
  domains: Domain[];
  nameServers: NameServer[];
  dnsRecords: DnsRecord[];
  submit: ElementState;
}

export const initialDomainState: DomainState = {
  domains: [],
  nameServers: [],
  dnsRecords: [],
  submit: new ElementState(),
};
