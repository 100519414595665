export interface AllocationCreate {
  name: string;
};

export interface AllocationUpdate {
  name: string;
  description?: string;
  active: number;
};

export interface Allocation {
  id: number;
  name: string;
  description?: string;
  active: number;
  archive: number;
  dateCreated: string;
  dateUpdated: string;
  createdBy: number;
  updatedBy: number;
};
