import { createReducer, on } from '@ngrx/store';
import { createCompany, createCompanyError, createCompanySuccess, deleteCompany, deleteCompanyError, deleteCompanySuccess, getCompanies, getCompaniesError, getCompaniesSuccess, selectCompany, updateCompany, updateCompanyError, updateCompanySuccess } from '../actions';
import { ElementState, initialCompanyState } from '../states';

export const companyReducer = createReducer(
  initialCompanyState,
  on(getCompanies, (state) => {
    return {
      ...state,
      submit: ElementState.create(true),
    };
  }),
  on(getCompaniesSuccess, (state, companyList) => {
    return {
      ...state,
      submit: ElementState.success('Success'),
      companies: companyList.companies,
    };
  }),
  on(getCompaniesError, (state, error) => {
    return {
      ...state,
      submit: ElementState.error(error),
    };
  }),
  on(selectCompany, (state, companyId) => {
    return {
      ...state,
      selected: companyId.value
    };
  }),
  on(createCompany, (state) => {
    return {
      ...state,
      submit: ElementState.create(true),
    };
  }),
  on(createCompanySuccess, (state, company) => {
    return {
      ...state,
      submit: ElementState.success('Success'),
      companies: [
        company.value,
        ...state.companies,
      ],
    };
  }),
  on(createCompanyError, (state, error) => {
    return {
      ...state,
      submit: ElementState.error(error),
    };
  }),
  on(updateCompany, (state) => {
    return {
      ...state,
      submit: ElementState.create(true),
    };
  }),
  on(updateCompanySuccess, (state, companyUpdate) => {
    const updatedIndex = state.companies.findIndex((company) => company.companyId == companyUpdate.value.companyId);
    return {
      ...state,
      submit: ElementState.success('Success'),
      companies: [
        ...state.companies.slice(0, updatedIndex),
        companyUpdate.value,
        ...state.companies.slice(updatedIndex + 1),
      ],
    };
  }),
  on(updateCompanyError, (state, error) => {
    return {
      ...state,
      submit: ElementState.error(error),
    };
  }),
  on(deleteCompany, (state) => {
    return {
      ...state,
      submit: ElementState.create(true),
    };
  }),
  on(deleteCompanySuccess, (state, companyDeleted) => {
    const updatedIndex = state.companies.findIndex((company) => company.companyId == companyDeleted.id);
    return {
      ...state,
      submit: ElementState.success('Success'),
      companies: [
        ...state.companies.slice(0, updatedIndex),
        ...state.companies.slice(updatedIndex + 1),
      ],
    };
  }),
  on(deleteCompanyError, (state, error) => {
    return {
      ...state,
      submit: ElementState.error(error),
    };
  }),
);
