<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content class="mat-typography">
  <ng-container *ngIf="displayForm" (ngSubmit)="$event.preventDefault()">
    <lib-json-schema-form [state]="state"></lib-json-schema-form>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button *ngIf="displayForm && (data.mode != 'view')" [disabled]="formStatus!='VALID'" (click)="save()">Save</button>
</mat-dialog-actions>
