import { ActionReducerMap } from '@ngrx/store';
import { AppState } from '../states';

import * as AuthReducers from './auth.reducer';
import * as CompanyReducers from './company.reducer';
import * as ProductReducers from './product.reducer';
import * as SelectOptionsReducers from './select-options.reducer';
import * as UserReducers from './user.reducer';

export const reducers: ActionReducerMap<AppState> = {
  auth: AuthReducers.authReducer,
  companies: CompanyReducers.companyReducer,
  products: ProductReducers.productReducer,
  selectOptions: SelectOptionsReducers.selectOptionsReducer,
  user: UserReducers.userReducer,
};
