import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { ElementState, UserState } from '../states';
import { User } from '../../models';

const getUser = (state: UserState) => state.user;
const getUserSubmit = (state: UserState) => state.submit;

export const selectUserState: MemoizedSelector<object, UserState> = createFeatureSelector<UserState>('user');

export const selectUser: MemoizedSelector<object, User> = createSelector(
  selectUserState,
  getUser,
);

export const selectUserSubmit: MemoizedSelector<object, ElementState> = createSelector(
  selectUserState,
  getUserSubmit,
);

