import { AfterContentInit, AfterViewInit, Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, take, takeWhile } from 'rxjs';
import { Store, select } from '@ngrx/store';

import { Schema, State } from '@dashjoin/json-schema-form';

import * as MyStore from '../../../store';
import { CategoryBranch, NamedSchemaObj, ProductCreateObj, ProductDataObj } from 'src/app/models';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppSettings } from 'src/app/app.settings';
import { Product } from '../../../models';

@Component({
  selector: 'app-upsert-product',
  templateUrl: './upsert-product.component.html',
  styleUrls: ['./upsert-product.component.scss']
})
export class UpsertProductComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentInit {
  alive = true;
  title: string;
  selectedCategoryState$: Observable<CategoryBranch>;
  @Output() save$ = new EventEmitter<any>();
  labelForm: FormGroup;
  formValue = null;
  formStatus: string = 'INVALID';

  displayForm: boolean = false;
  categoryName: string = '';
  state: State = {
    schema: {
      type: "object",
      required: ["Loading"],
      properties: {
        Loading: {
          type: "string"
        },
      },
    },
    name: 'Loading',
    value: {},
    control: new FormGroup({})
  };

  constructor(
    private logger: NGXLogger,
    private dialogRef: MatDialogRef<any>,
    private store$: Store<MyStore.AppState>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: {mode: string, product: Product }, // new, edit, view
  ) {
    this.logger.log('UpsertProductComponent constructor');

    if(data.mode == 'new') {
      this.title = 'Create new product';
      this.store$
      .pipe(select(MyStore.selectSelectedCategory))
      .pipe(take(1))
      .subscribe((category: CategoryBranch) => {
        this.title = 'Create new product in ' + category.name;
      });
    } else if(data.mode == 'edit') {
      this.title = 'Edit product ' + data.product.label;
    } else {
      this.title = 'View product';
    }

    if(!data.product) {
      this.labelForm = this.fb.group ({
        label: ['', [Validators.required, Validators.pattern(AppSettings.regexComplete)]],
      });
    } else {
      this.labelForm = this.fb.group ({
        label: [data.product.label, [Validators.required, Validators.pattern(AppSettings.regexComplete)]],
      });
    }

    this.selectedCategoryState$ = this.store$
      .pipe(select(MyStore.selectSelectedCategory))
      .pipe(takeWhile(() => this.alive));
  }

  ngOnInit() {
    this.logger.log('UpsertProductComponent ngOnInit');

    this.selectedCategoryState$.subscribe((category: CategoryBranch) => {
      this.categoryName = category.name;
    });

    this.store$
      .pipe(select(MyStore.selectSelectedCategory))
      .pipe(take(1))
      .subscribe((category: CategoryBranch) => {
        this.store$.dispatch(MyStore.getCategorySchema({value: category.id}));
      });

    this.store$
      .pipe(select(MyStore.selectSelectedCategorySchema))
      .pipe(takeWhile(() => this.alive))
      .subscribe((namedSchemaObj: NamedSchemaObj | null) => {
        this.logger.log('UpsertProductComponent ngOnInit schema',namedSchemaObj);
        if(!namedSchemaObj != null) {
          if(namedSchemaObj?.schema && Object.keys(namedSchemaObj?.schema).length !== 0) this.createStateFromSchema(namedSchemaObj?.schema, this.data.product);
        }
      });
  }

  ngAfterViewInit() {
    this.logger.log('UpsertProductComponent ngAfterViewInit');
  }
  ngAfterContentInit() {
    this.logger.log('UpsertProductComponent ngAfterContentInit');
  }

  checkInitialSchema(state: State) {
    return JSON.parse(JSON.stringify(state.schema)) === '{}';
  }

  createStateFromSchema(schema: Schema, product: Product) {
    this.logger.log('UpsertProductComponent createStateFrom Schema', schema);
    this.state = {
      schema: schema,
      name: this.categoryName,
      value: (product && product.data)? product.data : {},
      control: (schema.type == 'object')? new FormGroup({}) : ((schema.type == 'array')? new FormArray([]) : new FormControl('')),
    }
    this.logger.log('UpsertProductComponent createStateFrom state', this.state);
    this.state.control.valueChanges.subscribe((res: any) => {
      this.formValue = res;
    });
    setTimeout(() => {
      this.state.control.statusChanges.subscribe((status: string) => {
        this.formStatus = status;
      });
    }, 1);
    this.displayForm = true;
  }

  save() {
    this.logger.log('UpsertProductComponent save');
    const retval = {
      label: this.labelForm.value.label,
      data: this.formValue,
    }
    this.logger.log('retval', retval);
    this.save$.emit(retval);
  }

  duplicate() {
    const productCreate = {
      label: this.labelForm.value.label,
      data: this.formValue,
    }
    this.dialogRef.close(productCreate);
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

}
