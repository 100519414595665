import { createReducer, on } from '@ngrx/store';
import { createSelectOptions, createSelectOptionsSuccess, createSelectOptionsError, getSelectOptions, getSelectOptionsSuccess, getSelectOptionsError, updateSelectOptions, updateSelectOptionsSuccess, updateSelectOptionsError, deleteSelectOptionsError, deleteSelectOptionsSuccess, deleteSelectOptions } from '../actions';
import { ElementState, initialSelectOptionsState } from '../states';

export const selectOptionsReducer = createReducer(
  initialSelectOptionsState,
  on(getSelectOptions, (state) => {
    return {
      ...state,
      submit: ElementState.create(true),
    };
  }),
  on(getSelectOptionsSuccess, (state, selectOptions) => {
    return {
      ...state,
      submit: ElementState.success('Success'),
      selectOptions: selectOptions.value,
    };
  }),
  on(getSelectOptionsError, (state, error) => {
    return {
      ...state,
      submit: ElementState.error(error),
    };
  }),
  on(createSelectOptions, (state, selectOptions) => {
    return {
      ...state,
      submit: ElementState.create(true),
    };
  }),
  on(createSelectOptionsSuccess, (state, selectOptions) => {
    return {
      ...state,
      submit: ElementState.success('Success'),
      selectOptions: [
        selectOptions.value,
        ...state.selectOptions,
      ],
    };
  }),
  on(createSelectOptionsError, (state, error) => {
    return {
      ...state,
      submit: ElementState.error(error),
    };
  }),
  on(updateSelectOptions, (state, selectOptions) => {
    return {
      ...state,
      submit: ElementState.create(true),
    };
  }),
  on(updateSelectOptionsSuccess, (state, selectOptionsUpdate) => {
    const updatedIndex = state.selectOptions.findIndex((selectOptions) => selectOptions.id == selectOptionsUpdate.value.id);
    return {
      ...state,
      submit: ElementState.success('Success'),
      selectOptions: [
        ...state.selectOptions.slice(0, updatedIndex),
        selectOptionsUpdate.value,
        ...state.selectOptions.slice(updatedIndex + 1),
      ],
    };
  }),
  on(updateSelectOptionsError, (state, error) => {
    return {
      ...state,
      submit: ElementState.error(error),
    };
  }),
  on(deleteSelectOptions, (state) => {
    return {
      ...state,
      submit: ElementState.create(true),
    };
  }),
  on(deleteSelectOptionsSuccess, (state, selectOptionsDelete) => {
    const updatedIndex = state.selectOptions.findIndex((selectOptions) => selectOptions.id == selectOptionsDelete.id);
    return {
      ...state,
      submit: ElementState.success('Success'),
      selectOptions: [
        ...state.selectOptions.slice(0, updatedIndex),
        ...state.selectOptions.slice(updatedIndex + 1),
      ],
    };
  }),
  on(deleteSelectOptionsError, (state, error) => {
    return {
      ...state,
      submit: ElementState.error(error),
    };
  }),
);
