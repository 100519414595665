<h2 mat-dialog-title>{{title}}</h2>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="schemaForm" (ngSubmit)="$event.preventDefault()">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput cdkFocusInitial formControlName="name">
    </mat-form-field>
    <!-- <mat-form-field>
      <mat-label>Schema</mat-label>
      <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="50" formControlName="schema" (keydown)="handleKeydown($event)"></textarea>
    </mat-form-field> -->
  </form>
</mat-dialog-content>
<app-schema-builder></app-schema-builder>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button *ngIf="(data.mode == 'view')" (click)="duplicate()">Duplicate</button>
  <!-- <button mat-button *ngIf="(data.mode != 'view')" [disabled]="!isValidSchema" (click)="beautify()">Beautify</button> -->
  <button mat-button *ngIf="(data.mode != 'view')" [disabled]="!isValidSchema()" (click)="save()">Save</button>
</mat-dialog-actions>
