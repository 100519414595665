import { Tokens } from '../../models';
import { ElementState } from './element.state';

export interface AuthState {
  readonly tokens: Tokens;
  readonly submit: ElementState;
}

export const initialAuthState: AuthState = {
  tokens: {
    'refresh-token': null,
    'access-token': null,
    'cognito-id-token': null,
    'cognito-access-token': null,
  },
  submit: new ElementState(),
};
