import { Warehouse, Allocation, Product, ProductConfig, InventoryEntry } from '../../models';
import { ElementState } from './element.state';

export interface InventoryState {
  warehouses: Warehouse[];
  allocations: Allocation[];
  products: Product[];
  productConfigs: ProductConfig[];
  inventoryEntries: InventoryEntry[];
  submit: ElementState;
}

export const initialInventoryState: InventoryState = {
  warehouses: [],
  allocations: [],
  products: [],
  productConfigs: [],
  inventoryEntries: [],
  submit: new ElementState(),
};
