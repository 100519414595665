import { SelectOptions } from '../../models';
import { ElementState } from './element.state';

export interface SelectOptionsState {
  selectOptions: SelectOptions[];
  submit: ElementState;
}

export const initialSelectOptionsState: SelectOptionsState = {
  selectOptions: [],
  submit: new ElementState(),
};
