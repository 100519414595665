import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';
import * as MyStore from '../store';

@Injectable()
export class HttpSelectedcompanyInterceptor implements HttpInterceptor {
  private selectedCompany: string | null;
  alive = true;

  constructor(
    private store$: Store<MyStore.AppState>,
  ) {
    this.selectedCompany = null;

    this.store$
      .pipe(select(MyStore.selectSelectedCompany))
      .pipe(takeWhile(() => this.alive))
      .subscribe((selectedCompany) => {
        this.selectedCompany = selectedCompany;
      });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (this.selectedCompany != null) {
      const authReq = req.clone({
        headers: req.headers.set('selected-company', this.selectedCompany),
      });
      return next.handle(authReq);
    }
    return next.handle(req);
  }
}
