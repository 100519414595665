<h2 mat-dialog-title>{{title}}</h2>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="form" (ngSubmit)="$event.preventDefault()">
    <mat-form-field>
      <mat-label>Company name</mat-label>
      <input matInput cdkFocusInitial formControlName="companyName">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Name Id</mat-label>
      <input matInput formControlName="nameId">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Schema</mat-label>
      <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="10" formControlName="description"></textarea>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [disabled]="!form.valid" (click)="save()">Save</button>
</mat-dialog-actions>
