<h2 mat-dialog-title>Assign schema</h2>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="form" (ngSubmit)="$event.preventDefault()">
    <mat-form-field>
      <mat-label>Schema</mat-label>
      <input matInput cdkFocusInitial formControlName="schemaId" [matAutocomplete]="auto">
      <mat-autocomplete [panelWidth]="400" #auto="matAutocomplete" (optionSelected)="onSelected($event.option.value)" [displayWith]="displayWith">
        <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
        <ng-container *ngIf="!isLoading">
          <mat-option *ngFor="let schema of filteredSchemas" [value]="schema">
            <span>{{schema.name}}</span>
          </mat-option>
        </ng-container>
      </mat-autocomplete>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [disabled]="namedSchema==null" (click)="assign()">Assign</button>
</mat-dialog-actions>
