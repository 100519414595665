import { Component, OnDestroy, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Store, select } from '@ngrx/store';
import { Observable, takeWhile } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import * as MyStore from '../../store';
import { NamedSchemaObj } from '../../models';
import { AssignSchemaComponent } from './assign-schema/assign-schema.component';
import { UpsertSchemaComponent } from './upsert-schema/upsert-schema.component';

@Component({
  selector: 'app-schemas',
  templateUrl: './schemas.component.html',
  styleUrls: ['./schemas.component.scss']
})
export class SchemasComponent implements OnInit, OnDestroy {
  alive = true;
  schemas$: Observable<NamedSchemaObj[]>;
  defaultSchema$: Observable<NamedSchemaObj | null>;
  sampleSchemas$: Observable<NamedSchemaObj[]>;

  constructor(
    private logger: NGXLogger,
    private store$: Store<MyStore.AppState>,
    private dialog: MatDialog,
  ) {
    this.logger.log('SchemasComponent constructor');

    this.schemas$ = this.store$
      .pipe(select(MyStore.selectSchemas))
      .pipe(takeWhile(() => this.alive));

    this.defaultSchema$ = this.store$
      .pipe(select(MyStore.selectDefaultSchema))
      .pipe(takeWhile(() => this.alive));

    this.sampleSchemas$ = this.store$
      .pipe(select(MyStore.selectSampleSchemas))
      .pipe(takeWhile(() => this.alive));
  }

  ngOnInit() {
    this.logger.log('SchemasComponent ngOnInit');

    this.store$.dispatch(MyStore.getDefaultNamedSchema());
    this.store$.dispatch(MyStore.getNamedSchemas({pageSize: null,pageOffset: null,includeTotal: null}));
    this.store$.dispatch(MyStore.getSampleNamedSchemas());
  }

  assignDefaultSchemaDialog(): void {
    const dialogRef = this.dialog.open(AssignSchemaComponent, {
      data: {mode: 'default'},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }

  createNewSchemaDialog(): void {
    const dialogRef = this.dialog.open(UpsertSchemaComponent, {
      disableClose: true,
      data: {mode: 'new'},
      width: '80vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }

  editSchemaDialog(namedSchema: NamedSchemaObj): void {
    this.logger.log('SchemasComponent editSchemaDialog', namedSchema);

    const dialogRef = this.dialog.open(UpsertSchemaComponent, {
      disableClose: true,
      data: {mode: 'edit', namedSchema: namedSchema},
      width: '80vw',
    });
  }

  viewSchemaDialog(namedSchema: NamedSchemaObj): void {
    this.logger.log('SchemasComponent viewSchemaDialog', namedSchema);

    const dialogRef = this.dialog.open(UpsertSchemaComponent, {
      disableClose: true,
      data: {mode: 'view', namedSchema: namedSchema},
      width: '80vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('result', result);
      if(result) {
        this.duplicateSchemaDialog(result as NamedSchemaObj);
      }
    });
  }

  duplicateSchemaDialog(namedSchema: NamedSchemaObj): void {
    this.logger.log('SchemasComponent duplicateSchemaDialog', namedSchema);

    const dialogRef = this.dialog.open(UpsertSchemaComponent, {
      disableClose: true,
      data: {mode: 'new', namedSchema: namedSchema},
      width: '80vw',
    });
  }

  deleteSchemaDialog(schemaId: string): void {
    this.logger.log('SchemasComponent deleteSchemaDialog', schemaId);
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
}
