import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { Schema } from '@dashjoin/json-schema-form';

import * as MyActions from '../actions';
import { ProductService } from '../../services';
import { CategoryBranch, CategoryUpsert, NamedSchemaCreateObj, NamedSchemaIdObj, NamedSchemaObj, Product, ProductCreateObj, PropertyCreateObj, PropertyObj, User } from '../../models';

@Injectable()
export class ProductEffects {
  constructor(
    private actions$: Actions,
    private productService: ProductService,
    private logger: NGXLogger,
  ) { }

  getCategories$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.getCategories),
    switchMap(() =>
      this.productService.getCategories().pipe(
        map((categories: CategoryBranch[]) => MyActions.getCategoriesSuccess({ "value": categories})),
        catchError((error) => of(MyActions.getCategoriesError(error))),
      ),
    ),
  ));

  addCategory$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.addCategory),
    switchMap((categoryUpsert: { value: CategoryUpsert }) =>
      this.productService.addCategory(categoryUpsert.value).pipe(
        map((categories: CategoryBranch[]) => MyActions.addCategorySuccess({ "value": categories})),
        catchError((error) => of(MyActions.createSchemaError(error))),
      ),
    ),
  ));

  editCategory$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.editCategory),
    switchMap((categoryUpsert: { id: string,  value: CategoryUpsert }) =>
      this.productService.editCategory(categoryUpsert.id, categoryUpsert.value).pipe(
        map((categories: CategoryBranch[]) => MyActions.editCategorySuccess({ "value": categories})),
        catchError((error) => of(MyActions.editCategoryError(error))),
      ),
    ),
  ));

  editCategorySuccess$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.editCategorySuccess),
    switchMap(() => ([
      MyActions.unselectCategory(),
    ])),
  ));

  deleteCategory$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.deleteCategory),
    switchMap((category: { id: string }) =>
      this.productService.deleteCategory(category.id).pipe(
        map((categories: CategoryBranch[]) => MyActions.deleteCategorySuccess({ "value": categories})),
        catchError((error) => of(MyActions.deleteCategoryError(error))),
      ),
    ),
  ));

  deleteCategorySuccess$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.deleteCategorySuccess),
    switchMap(() => ([
      MyActions.unselectCategory(),
    ])),
  ));

  removeUser$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.removeUser),
    map(() => {
      this.logger.log('removeUser$ effect');
    }),
  ), { dispatch: false });

  getProductsInCategory$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.getProductsInCategories),
    switchMap((categoryId: {value:string}) =>
      this.productService.getProductsInCategory(categoryId.value).pipe(
        map((products: Product[]) => MyActions.getProductsInCategoriesSuccess({ "value": products})),
        catchError((error) => of(MyActions.getCategoriesError(error))),
      ),
    ),
  ));

  getCategorySchema$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.getCategorySchema),
    switchMap((categoryId: {value:string}) =>
      this.productService.getSchemaOfCategory(categoryId.value).pipe(
        map((schema: NamedSchemaObj) => MyActions.getCategorySchemaSuccess({ "value": schema})),
        catchError((error) => of(MyActions.getCategorySchemaError(error))),
      ),
    ),
  ));

  assignCategorySchema$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.assignCategoryNamedSchema),
    switchMap((schemaAssign: { categoryId: string, value: NamedSchemaIdObj }) =>
      this.productService.assignCategorySchema(schemaAssign.value, schemaAssign.categoryId).pipe(
        map((schema: NamedSchemaObj) => MyActions.assignCategoryNamedSchemaSuccess({ "value": schema})),
        catchError((error) => of(MyActions.assignCategoryNamedSchemaError(error))),
      ),
    ),
  ));

  clearCategorySchema$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.unselectCategory),
    switchMap(() => ([
      MyActions.clearCategorySchema(),
      MyActions.clearProducts(),
    ])),
  ));

  createNamedSchema$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.createNamedSchema),
    switchMap((schema: { value: NamedSchemaCreateObj }) =>
      this.productService.createSchema(schema.value).pipe(
        map((schema: NamedSchemaObj) => MyActions.createSchemaSuccess({ "value": schema})),
        catchError((error) => of(MyActions.createSchemaError(error))),
      ),
    ),
  ));

  getNamedSchema$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.getNamedSchema),
    switchMap((schemaId: { value: string }) =>
      this.productService.getSchema(schemaId.value).pipe(
        map((schema: NamedSchemaObj) => MyActions.getNamedSchemaSuccess({ "value": schema})),
        catchError((error) => of(MyActions.getNamedSchemaError(error))),
      ),
    ),
  ));

  getDefaultNamedSchema$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.getDefaultNamedSchema),
    switchMap(() =>
      this.productService.getDefaultSchema().pipe(
        map((schema: NamedSchemaObj) => MyActions.getDefaultNamedSchemaSuccess({ "value": schema})),
        catchError((error) => of(MyActions.getDefaultNamedSchemaError(error))),
      ),
    ),
  ));

  assignDefaultNamedSchema$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.assignDefaultNamedSchema),
    switchMap((schemaId: { value: NamedSchemaIdObj }) =>
      this.productService.assignDefaultSchema(schemaId.value).pipe(
        map((schema: NamedSchemaObj) => MyActions.assignDefaultNamedSchemaSuccess({ "value": schema})),
        catchError((error) => of(MyActions.assignDefaultNamedSchemaError(error))),
      ),
    ),
  ));

  getSampleNamedSchemas$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.getSampleNamedSchemas),
    switchMap(() =>
      this.productService.getSampleSchemas().pipe(
        map((schemas: NamedSchemaObj[]) => MyActions.getSampleNamedSchemasSuccess({ "value": schemas})),
        catchError((error) => of(MyActions.getSampleNamedSchemasError(error))),
      ),
    ),
  ));

  getNamedSchemas$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.getNamedSchemas),
    switchMap((schemaFetch: { pageSize: number | null, pageOffset: number | null, includeTotal: boolean | null }) =>
      this.productService.getSchemas(schemaFetch.pageSize,schemaFetch.pageOffset,schemaFetch.includeTotal, null).pipe(
        map((schemas: NamedSchemaObj[]) => MyActions.getNamedSchemasSuccess({ "value": schemas})),
        catchError((error) => of(MyActions.getNamedSchemasError(error))),
      ),
    ),
  ));

  updateNamedSchema$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.updateNamedSchema),
    switchMap((schema: { id: string, value: NamedSchemaCreateObj }) =>
      this.productService.updateSchema(schema.id, schema.value).pipe(
        map((schema: NamedSchemaObj) => MyActions.updateNamedSchemaSuccess({ "value": schema})),
        catchError((error) => of(MyActions.updateNamedSchemaError(error))),
      ),
    ),
  ));

  createProduct$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.createProduct),
    switchMap((product: { value: ProductCreateObj, categoryId: string }) =>
      this.productService.createProduct(product.value, product.categoryId).pipe(
        map((product: Product) => MyActions.createProductSuccess({ "value": product})),
        catchError((error) => of(MyActions.createProductError(error))),
      ),
    ),
  ));

  updateProduct$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.updateProduct),
    switchMap((product: { value: ProductCreateObj, productId: string }) =>
      this.productService.updateProduct(product.value, product.productId).pipe(
        map((product: Product) => MyActions.updateProductSuccess({ "value": product})),
        catchError((error) => of(MyActions.updateProductError(error))),
      ),
    ),
  ));

  deleteProduct$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.deleteProduct),
    switchMap((product: { id: string }) =>
      this.productService.deleteProduct(product.id).pipe(
        map(() => MyActions.deleteProductSuccess({ id: product.id })),
        catchError((error) => of(MyActions.deleteProductError(error))),
      ),
    ),
  ));

  createCategoryProperties$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.createCategoryProperties),
    switchMap((property: { value: PropertyCreateObj, categoryId: string }) =>
      this.productService.createProperty(property.value, property.categoryId).pipe(
        map((categoryProperty: PropertyObj) => MyActions.createCategoryPropertiesSuccess({ "value": categoryProperty})),
        catchError((error) => of(MyActions.createCategoryPropertiesError(error))),
      ),
    ),
  ));

  getCategoryProperties$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.getCategoryProperties),
    switchMap((property: { value: string }) =>
      this.productService.getCategoryProperties(property.value).pipe(
        map((categoryProperties: PropertyObj[]) => MyActions.getCategoryPropertiesSuccess({ "value": categoryProperties})),
        catchError((error) => of(MyActions.getCategoryPropertiesError(error))),
      ),
    ),
  ));

  updateCategoryProperty$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.updateCategoryProperty),
    switchMap((property: { value: PropertyCreateObj, propertyId: string }) =>
      this.productService.updateCategoryProperty(property.value, property.propertyId).pipe(
        map((property: PropertyObj) => MyActions.updateCategoryPropertySuccess({ "value": property})),
        catchError((error) => of(MyActions.updateCategoryPropertyError(error))),
      ),
    ),
  ));

  deleteCategoryProperty$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.deleteCategoryProperty),
    switchMap((property: { id: string }) =>
      this.productService.deleteCategoryProperty(property.id).pipe(
        map(() => MyActions.deleteCategoryPropertySuccess({ id: property.id})),
        catchError((error) => of(MyActions.deleteCategoryPropertyError(error))),
      ),
    ),
  ));
}
