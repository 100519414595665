import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';

import { Company, CompanyCreate, CompanyDelete } from '../models';
import { environment } from '../../environments/environment';
import { AppSettings } from '../app.settings';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
  ) {
    this.logger.log('CompanyService constructor');
  }

  /**
 * Calls a getCompany http request
 * @return {Observable<Company[]>}
 */
  getCompanies(): Observable<Company[]> {
    this.logger.log('CompanyService getCompanies');

    const apiPath = AppSettings.pathCompanies;
    return this.http.get<Company[]>(environment.apiUrl + apiPath);
  }

  /**
 * Calls a createCompany http request
 * @param {company} company
 * @return {Observable<Company>}
 */
  createCompany(company: CompanyCreate): Observable<Company> {
    this.logger.log('CompanyService createCompany');

    const apiPath = AppSettings.pathCompanies;
    return this.http.post<Company>(environment.apiUrl + apiPath, company);
  }

  /**
 * Calls a updateCompany http request
 * @param {company} company
 * @return {Observable<Company>}
 */
  updateCompany(companyId: string, company: CompanyCreate): Observable<Company> {
    this.logger.log('CompanyService updateCompany');

    const apiPath = AppSettings.pathCompanies + '/id/' + companyId;
    return this.http.put<Company>(environment.apiUrl + apiPath, company);
  }

  /**
 * Calls a deleteCompany http request
 * @param {company} company
 * @return {Observable<CompanyDelete>}
 */
  deleteCompany(companyId: string): Observable<CompanyDelete> {
    this.logger.log('CompanyService deleteCompany');

    const apiPath = AppSettings.pathCompanies + '/id/' + companyId;
    return this.http.delete<CompanyDelete>(environment.apiUrl + apiPath);
  }
}
