<mat-card class="general-card">
  <mat-card-header>
    <mat-card-title-group>
      <mat-card-title>Options</mat-card-title>
      <mat-card-subtitle>Options you can use</mat-card-subtitle>
      <button mat-icon-button aria-label="options" [matMenuTriggerFor]="companySchemaOptions">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #companySchemaOptions="matMenu">
        <button mat-menu-item (click)="createOptions()">Create options</button>
      </mat-menu>
    </mat-card-title-group>
  </mat-card-header>
  <ng-container *ngIf="selectOptions$ | async as selectOptions">
    <mat-card-content>
      <mat-list role="list">
        <ng-container *ngFor="let options of selectOptions">
          <mat-list-item >
            <div matListItemIcon class="button-container-fix">
              <button mat-icon-button class="no-vert-padding" aria-label="options" [matMenuTriggerFor]="selectOptionsOptions">
                <mat-icon >more_vert</mat-icon>
              </button>
              <mat-menu #selectOptionsOptions="matMenu">
                <button mat-menu-item (click)="viewOptions(options)">View</button>
                <button mat-menu-item (click)="editOptions(options)">Edit</button>
                <button mat-menu-item (click)="deleteOptions(options)">Delete</button>
              </mat-menu>
            </div>
            <div matListItemTitle>{{options.label}}</div>
          </mat-list-item>
          <mat-divider></mat-divider>
        </ng-container>
      </mat-list>
    </mat-card-content>
  </ng-container>
</mat-card>
