import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Preferences } from '@capacitor/preferences';
import { NGXLogger } from 'ngx-logger';

import * as MyActions from '../actions';
import { AuthService } from '../../services';
import { Tokens, TokensForAccess } from '../../models';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private logger: NGXLogger,
  ) { }

  getTokensFromCode$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.getTokens),
    switchMap(() =>
      this.authService.getTokensFromCode().pipe(
        map((tokens: Tokens) => MyActions.getTokensSuccess(tokens)),
        catchError((error) => of( MyActions.getTokensError(error))),
      ),
    ),
  ));

  getTokensSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.getTokensSuccess),
    map((tokens: Tokens) => {
      this.authService.loginSuccess(tokens);
      if (tokens['refresh-token'] != null) {
        Preferences.set({
          key: 'refresh-token',
          value: tokens['refresh-token'],
        });
      }
    }),
    switchMap(() => [
      MyActions.getUser(),
    ]),
  ));

  reloadRefreshToken$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.reloadRefreshToken),
    switchMap(() => [
      MyActions.refreshTokens({ value: true }),
    ]),
  ));

  getTokensFromRefresh$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.refreshTokens),
    switchMap((fetchData: { value: boolean }) =>
      this.authService.getTokensFromRefresh().pipe(
        map((tokensForAccess: TokensForAccess) => MyActions.refreshTokensSuccess({ value: tokensForAccess, fetchData: fetchData.value })),
        catchError((error) => of(MyActions.refreshTokensError(error))),
      ),
    ),
  ));

  getTokensFromRefreshSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.refreshTokensSuccess),
    // map((tokensForAccess: { value: TokensForAccess, fetchData: boolean }) => {
    //   this.logger.log('AuthEffects getTokensFromRefreshSuccess$');
    // }),
    switchMap((tokensForAccess: { value: TokensForAccess, fetchData: boolean }) => (tokensForAccess.fetchData)?[
      MyActions.getUser(),
      MyActions.getCompanies(),
    ]:[
      MyActions.nullAction(),
    ]),
  ));

  getTokensFromRefreshError$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.refreshTokensError),
    switchMap(() => {
      Preferences.remove({
        key: 'refresh-token',
      });
      return this.authService.revokeToken().pipe(
        mergeMap(() => [
          MyActions.logoutSuccess(),
          MyActions.removeUser(),
        ]),
        catchError((error) => of(
          MyActions.logoutError(error),
          MyActions.removeUser(),
        )),
      );
    },
    ),
  ));

  logout$ = createEffect(() => this.actions$.pipe(
    ofType(MyActions.logout),
    switchMap(() => {
      // Preferences.remove({
      //   key: 'refresh-token',
      // });
      Preferences.clear();
      return this.authService.revokeToken().pipe(
        mergeMap(() => [
          MyActions.logoutSuccess(),
          MyActions.removeUser(),
        ]),
        catchError((error) => of(
          MyActions.logoutError(error),
          MyActions.removeUser(),
        )),
      );
    },
    ),
  ));
}
