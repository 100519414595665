import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';

import * as MyStore from '../../../store';
import { Company, CompanyCreate } from '../../../models';
import { AppSettings } from '../../../app.settings';
import { Observable, takeWhile } from 'rxjs';

@Component({
  selector: 'app-upsert-company',
  templateUrl: './upsert-company.component.html',
  styleUrls: ['./upsert-company.component.scss']
})
export class UpsertCompanyComponent implements OnInit, OnDestroy {
  alive = true;
  title: string;
  form: FormGroup;
  companySubmitState$: Observable<MyStore.ElementState>;

  constructor(
    private logger: NGXLogger,
    private store$: Store<MyStore.AppState>,
    private dialogRef: MatDialogRef<any>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: {mode: string, company: Company }, // new, edit
  ) {
    this.logger.log('UpsertCompanyComponent constructor', data);

    this.companySubmitState$ = this.store$
      .pipe(select(MyStore.selectCompanySubmit))
      .pipe(takeWhile(() => this.alive));

    if(data.mode == 'new') {
      this.title = 'New company';
    } else {
      this.title = 'Edit company';
    }

    if(data.company == null) {
      this.form = this.fb.group ({
        companyName: ['', [Validators.required, Validators.pattern(AppSettings.regexComplete)]],
        nameId: ['', [Validators.required, Validators.pattern(AppSettings.regexAlphaNumericLowercase)]],
        description: ['', [Validators.required, Validators.pattern(AppSettings.regexComplete)]],
      });
    } else {
      this.form = this.fb.group ({
        companyName: [data.company.companyName, [Validators.required, Validators.pattern(AppSettings.regexComplete)]],
        nameId: [data.company.nameId, [Validators.required, Validators.pattern(AppSettings.regexAlphaNumericLowercase)]],
        description: [data.company.description, [Validators.required, Validators.pattern(AppSettings.regexComplete)]],
      });
    }
  }

  ngOnInit() {
    this.logger.log('UpsertCompanyComponent ngOnInit');
  }

  save(): void {
    this.logger.log('UpsertCompanyComponent save', this.form.value);
    if(!this.form.valid) {
      return;
    }

    if(this.data.mode == 'new') {
      const company: CompanyCreate = {
        nameId: this.form.value.nameId,
        companyName: this.form.value.companyName,
        description: this.form.value.description,
      }
      this.store$.dispatch(MyStore.createCompany({value: company}));

      this.companySubmitState$.subscribe((elementState) => {
        if(elementState.success) this.dialogRef.close();
        else if(elementState.hasFailed) {
          // TODO show error message
        }
      });
    } else {
      // edit
      const company: CompanyCreate = {
        nameId: this.form.value.nameId,
        companyName: this.form.value.companyName,
        description: this.form.value.description,
      }
      this.store$.dispatch(MyStore.updateCompany({id: this.data.company.companyId, value: company}));

      this.companySubmitState$.subscribe((elementState) => {
        if(elementState.success) this.dialogRef.close();
        else if(elementState.hasFailed) {
          // TODO show error message
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

}
