import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { Store, select } from '@ngrx/store';
import { Observable, debounceTime, distinctUntilChanged, filter, finalize, switchMap, takeWhile, tap } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import * as MyStore from '../../../store';
import { ProductService } from '../../../services';

import { CategoryBranch, NamedSchemaIdObj, NamedSchemaObj } from 'src/app/models';

@Component({
  selector: 'app-assign-schema',
  templateUrl: './assign-schema.component.html',
  styleUrls: ['./assign-schema.component.scss']
})
export class AssignSchemaComponent implements OnInit, OnDestroy {
  alive = true;
  form: FormGroup;

  namedSchema: NamedSchemaObj | null;
  filteredSchemas: NamedSchemaObj[];

  isLoading = false;
  minLengthTerm = 3;
  productSubmitState$: Observable<MyStore.ElementState>;

  constructor(
    private logger: NGXLogger,
    private store$: Store<MyStore.AppState>,
    private fb: FormBuilder,
    private productService: ProductService,
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) private data: {mode: string, category: CategoryBranch }, // default, category
  ) {
    this.logger.log('AssignSchemaComponent constructor');

    this.form = this.fb.group ({
      schemaId: ['', [Validators.required]],
    });

    this.namedSchema = null;
    this.filteredSchemas = [];

    this.productSubmitState$ = this.store$
      .pipe(select(MyStore.selectProductSubmit))
      .pipe(takeWhile(() => this.alive));
  }

  ngOnInit() {
    this.logger.log('AssignSchemaComponent ngOnInit');

    // TODO check data

    this.form.controls['schemaId'].valueChanges
      .pipe(
        filter(res => {
          return res !== null && res.length >= this.minLengthTerm
        }),
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => {
          this.filteredSchemas = [];
          this.isLoading = true;
        }),
        switchMap(value => this.productService.getSchemas(null,null,null, value as string)
          .pipe(
            finalize(() => {
              this.isLoading = false
            }),
          )
        )
      )
      .subscribe((data: NamedSchemaObj[]) => {
        if (data !== undefined) {
          this.logger.log('AssignSchemaComponent data',data);
          this.filteredSchemas = data;
        }
      });
  }

  onSelected(value: NamedSchemaObj) {
    this.logger.log('AssignSchemaComponent onSelected', value);
    this.namedSchema = value;
  }

  displayWith(value: NamedSchemaObj) {
    return value?.name;
  }

  clearSelection() {
    this.namedSchema = null;
    this.filteredSchemas = [];
  }

  assign() {
    this.logger.log('AssignSchemaComponent assign', this.namedSchema);

    // TODO show error
    if(this.namedSchema==null) return;

    const schemaId: NamedSchemaIdObj = {
      id: this.namedSchema.id,
    }

    if(this.data.mode == 'default') {
      this.store$.dispatch(MyStore.assignDefaultNamedSchema({value: schemaId}));

      this.productSubmitState$.subscribe((elementState) => {
        if(elementState.success) this.dialogRef.close();

        // TODO show error
      });
    } else {
      this.store$.dispatch(MyStore.assignCategoryNamedSchema({categoryId: this.data.category.id, value: schemaId}));

      this.productSubmitState$.subscribe((elementState) => {
        if(elementState.success) this.dialogRef.close();

        // TODO show error
      });
    }
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
}
