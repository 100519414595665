import { Component, OnDestroy, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Store } from '@ngrx/store';

import * as MyStore from '../../store';

@Component({
  selector: 'app-loggedin',
  templateUrl: './loggedin.component.html',
  styleUrls: ['./loggedin.component.scss']
})
export class LoggedinComponent implements OnInit, OnDestroy {
  alive = true;
  constructor(
    private logger: NGXLogger,
    private store$: Store<MyStore.AppState>,
  ) {
    this.logger.log('LoggedinComponent constructor');
  }

  ngOnInit() {
    this.logger.log('LoggedinComponent ngOnInit');
    this.store$.dispatch(MyStore.getTokens());
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

}
