import { ElementState, CompanyState } from '../states';
import { Address, Company } from '../../models';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

const getSelectCompany = (state: CompanyState) => state.selected;
const getCompanies = (state: CompanyState) => state.companies;
const getAddresses = (state: CompanyState) => state.addresses;
const getCompanySubmit = (state: CompanyState) => state.submit;

export const selectCompanyState: MemoizedSelector<object, CompanyState> = createFeatureSelector<CompanyState>('companies');

export const selectSelectedCompany: MemoizedSelector<object, string | null> = createSelector(
  selectCompanyState,
  getSelectCompany,
);

export const selectCompanies: MemoizedSelector<object, Company[]> = createSelector(
  selectCompanyState,
  getCompanies,
);

export const selectCompanyAddresses: MemoizedSelector<object, Address[]> = createSelector(
  selectCompanyState,
  getAddresses,
);

export const selectCompanySubmit: MemoizedSelector<object, ElementState> = createSelector(
  selectCompanyState,
  getCompanySubmit,
);

