import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent {

  constructor(
    private logger: NGXLogger,
    @Inject(MAT_DIALOG_DATA) public data: {title: string, content: string, closeLabel: string},
  ) {
    this.logger.log('CustomSaveDialogComponent constructor', data);
  }

}
