import { createAction } from '@ngrx/store';
import { SelectOptions, SelectOptionsCreate } from 'src/app/models';

export const getSelectOptions = createAction('[SelectOptions] GETSELECTOPTIONS', (selectOptionsFetch: { pageSize: number | null, pageOffset: number | null, includeTotal: boolean | null }) => selectOptionsFetch);
export const getSelectOptionsSuccess = createAction('[SelectOptions] GETSELECTOPTIONS_SUCCESS', (selectOptions: { value: SelectOptions[] }) => selectOptions);
export const getSelectOptionsError = createAction('[SelectOptions] GETSELECTOPTIONS_ERROR', (error: any) => error);

export const createSelectOptions = createAction('[SelectOptions] CREATESELECTOPTIONS', (selectOptions: { value: SelectOptionsCreate }) => selectOptions);
export const createSelectOptionsSuccess = createAction('[SelectOptions] CREATESELECTOPTIONS_SUCCESS', (selectOptions: { value: SelectOptions }) => selectOptions);
export const createSelectOptionsError = createAction('[SelectOptions] CREATESELECTOPTIONS_ERROR', (error: any) => error);

export const updateSelectOptions = createAction('[SelectOptions] UPDATESELECTOPTIONS', (selectOptions: { id: string, value: SelectOptionsCreate }) => selectOptions);
export const updateSelectOptionsSuccess = createAction('[SelectOptions] UPDATESELECTOPTIONS_SUCCESS', (selectOptions: { value: SelectOptions }) => selectOptions);
export const updateSelectOptionsError = createAction('[SelectOptions] UPDATESELECTOPTIONS_ERROR', (error: any) => error);

export const deleteSelectOptions = createAction('[SelectOptions] DELETESELECTOPTIONS', (selectOptions: { id: string }) => selectOptions);
export const deleteSelectOptionsSuccess = createAction('[SelectOptions] DELETESELECTOPTIONS_SUCCESS', (selectOptions: { id: string }) => selectOptions);
export const deleteSelectOptionsError = createAction('[SelectOptions] DELETESELECTOPTIONS_ERROR', (error: any) => error);
