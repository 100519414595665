import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TreeService {

  constructor() { }

  public getLvl(id: string, arrayObj: any): number {
    let jsonString = JSON.stringify(arrayObj);
    const idWithKey = '"id":"'+id+'"';

    jsonString = jsonString.split("\\\\").join('_');
    jsonString = jsonString.split("\\\"").join('_');
    jsonString = jsonString.replace(/"name":"[^"]*"/g, "");
    jsonString = jsonString.split(",,").join(',');
    jsonString = jsonString.split("{,").join('{');
    jsonString = jsonString.split(",}").join('}');

    const index = jsonString.indexOf(idWithKey);
    const substr = jsonString.substring(0, index+1);
    const open = (substr.match(/{/g) || []).length;
    const close = (substr.match(/}/g) || []).length;
    const lvl = open - close;
    return lvl;
  }

  public traverse(startLevel: number, targetLevel: number, id: string, arrayObj: any, parentage: any, childKey: string): {found: boolean, parentage: any} {
    const curLevel = startLevel + 1;
    let index = 0;
    for (let node of arrayObj) {
        if(curLevel == targetLevel) {
            if(node.id == id) {
                return {found:true, parentage:parentage};
            }
        } else {
            if(node[childKey]) {
                const cloneParentage = JSON.parse(JSON.stringify(parentage));
                cloneParentage.push({index: index, id: node.id});
                const retval = this.traverse(curLevel, targetLevel, id, node[childKey], cloneParentage, childKey);
                if(retval.found) return retval;
            }
        }
        index++;
    }
    return {found:false, parentage:parentage};
  }

  public getLeaves(arrayObj: any, childKey: string): any[] {
    let leaves: any[] = [];
    for (let node of arrayObj) {
      if(node[childKey] === undefined || node[childKey].length == 0) {
        leaves.push(node);
      } else {
        const childLeaves = this.getLeaves(node[childKey], childKey);
        if(childLeaves.length>0) leaves = leaves.concat(childLeaves);
      }
    }
    return leaves;
  }

  public getParentObject(id: string, arrayObj: any, childKey: string): any {
    const lvl = this.getLvl(id, arrayObj);
    const genealogy = this.traverse(0, lvl, id, arrayObj, [], childKey);

    let immediateParent: any = {};
    immediateParent[childKey] = arrayObj;
    if(genealogy.parentage.length>0) {
        for (let parent of genealogy.parentage) {
            immediateParent = immediateParent[childKey][parent.index];
        }
    }
    return immediateParent;
  }

  public getObject(id: string, arrayObj: any, childKey: string): any {
      const immediateParent = this.getParentObject(id, arrayObj, childKey);
      let catIndex = immediateParent[childKey].findIndex((node: any) => node.id == id);
      return immediateParent[childKey][catIndex];
  }

  public deleteObject(id: string, arrayObj: any, childKey: string): void {
      const immediateParent = this.getParentObject(id, arrayObj, childKey);
      let catIndex = immediateParent[childKey].findIndex((node: any) => node.id == id);
      immediateParent[childKey].splice(catIndex, 1);
  }

  public isCategoryLeaf(id: string, arrayObj: any, childKey: string): boolean {
    const categoryObject = this.getObject(id, arrayObj, childKey);
    if(categoryObject.hasOwnProperty(childKey)) return false;
    return true;
  }
}
