import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class RouteGuardService {
  constructor(
    public auth: AuthService,
    public router: Router
  ) {

  }

  canActivate(): boolean {
    return this.auth.isValidAccessToken();
  }
}
