export interface InventoryEntry {
  id: string;
  transactionId: string;
  transactionSequence: number;
  company: number;
  product: string;
  sku: string;
  warehouse: number;
  allocationMod: any;
  allocationCurrent: any;
  dateCreated: string;
  createdBy: number;
};

export interface AlterStock {
  quantity: number;
};

export interface StockTransfer {
  quantity: number;
  warehouseTo: number;
  allocationTo: number;
};
