export interface AddressCreate {
  street1: string;
  street2?: string;
  city: string;
  province: number;
  country: number;
  zipCode: number;
};

export interface Address {
  id: number;
  street1: string;
  street2?: string;
  city: string;
  province: number;
  country: number;
  zipCode: number;
  active: number;
  archive: number;
  dateCreated: string;
  dateUpdated: string;
  createdBy: number;
  updatedBy: number;
};

export interface Country {
  countryCode: number;
  name: string;
  a2Code: string;
  a3Code: string;
};

export interface Province {
  id: number;
  name: string;
  country: number;
};
