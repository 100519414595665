export interface CategoryLeaf {
  id: string;
  name: string;
};

export interface CategoryBranch extends CategoryLeaf {
  categories?: CategoryBranch[];
};

export interface CategoryInfo extends CategoryLeaf {
  isLeaf: boolean;
  level: number;
};

export interface CategoryUpsert {
  name: string;
  refId: string | undefined;
  relation: string | undefined;
};
