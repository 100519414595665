import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit, OnDestroy {
  alive = true;
  form: FormGroup;
  @Output() delete$ = new EventEmitter<boolean>();

  constructor(
    private logger: NGXLogger,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: {title: string, label: string, confirmString: string },
  ) {
    this.logger.log('DeleteDialogComponent constructor', data);

    this.form = this.fb.group ({
      confirmString: ['', [Validators.required, Validators.pattern('^' + data.confirmString + '$')]],
    });
  }

  ngOnInit() {
    this.logger.log('DeleteDialogComponent ngOnInit');
  }

  delete(): void {
    this.logger.log('DeleteDialogComponent delete');
    this.delete$.emit(true);
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

}
