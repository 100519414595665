<div *ngIf="dataSource.data.length > 0; else emptySchema">
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
      <!-- use a disabled button to provide padding for tree leaf -->
        <button mat-icon-button disabled></button>
        <button mat-icon-button aria-label="options" [matMenuTriggerFor]="categoryLeafOptions">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #categoryLeafOptions="matMenu">
          <button mat-menu-item>View</button>
          <ng-container *ngIf="!viewOnly">
            <button mat-menu-item *ngIf="node.type == 'object'" (click)="addProperty(node.ref)">Add property</button>
            <button mat-menu-item *ngIf="node.type == 'array'" (click)="addProperty(node.ref)">Define item property</button>
            <button mat-menu-item (click)="editFull(node.ref)">Edit</button>
            <button mat-menu-item (click)="deleteProperty(node.ref)">Delete</button>
          </ng-container>
        </mat-menu>
        <span *ngIf="node.name">{{node.name}} : </span> {{node.type}} {{node.ref.required? '*': ''}}
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
      <button mat-icon-button matTreeNodeToggle
              [attr.aria-label]="'Toggle ' + node.name">
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
      </button>
      <button mat-icon-button aria-label="options" [matMenuTriggerFor]="categoryBranchOptions">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #categoryBranchOptions="matMenu">
        <button mat-menu-item>View</button>
        <ng-container *ngIf="!viewOnly">
          <button mat-menu-item *ngIf="node.type == 'object'" (click)="addProperty(node.ref)">Add property</button>
          <button mat-menu-item (click)="edit(node.ref)">Edit</button>
        </ng-container>
      </mat-menu>
      <span *ngIf="node.name">{{node.name}} : </span> {{node.type}} {{node.ref.required? '*': ''}}
    </mat-tree-node>
  </mat-tree>
</div>
<ng-template #emptySchema>
  <mat-list *ngIf="!viewOnly">
      <mat-list-item class="button-container-fix" style="text-align: center;">
        <button mat-icon-button class="no-vert-padding" (click)="add()">
          <mat-icon class="mat-24">add</mat-icon>
        </button>
      </mat-list-item>
      <mat-divider></mat-divider>
  </mat-list>
</ng-template>
